/**
 * BookingFormInfoStep
 * @module components/booking-form-info-step
 */
import HttpRequest from './HttpRequest.mjs';
import Step from './step.mjs';
import log from '../services/log.mjs';
import GoogleAutocomplete from '../services/google-autocomplete.mjs';
import PhoneNumberValidator from '../services/phone-number-validator.mjs';
import { FieldValidator } from '../services/field-validator.mjs';
import GoogleAddressValidation from '../services/google-address-validation.mjs';
import AddressAutoCompleteResult from './AddressAutoCompleteResult.mjs';
import ConnectionStatus from '../services/connection-status.mjs';
import AsyncHelper from '../services/async-helper.mjs';
import L from 'leaflet';

/**
 * BookingFormInfoStep
 */
class BookingFormInfoStep extends Step {
    name = 'info';

    endpoints = {
        countries: '/api/countries',
    };

    /**
     * @type {BookingForm}
     */
    bookingForm = null;

    containers = {
        selectedServices: null,
        clientInfo: null,
        addressConfirmation: null,
    };

    divisionId = null;

    GoogleAutocomplete = null;

    addressSuggestions = [];

    countriesLoaded = false;

    addressFieldContainer = null;

    addressValidator = null;

    /**
     *
     * @type {boolean}
     */
    userAddressConfirmation = false;

    connectionStatus = true;

    /**
     * Selected Services
     * @type {[]}
     */
    services = [];

    HTMLElement = null;

    #elements = {};

    #hooks = {
        selectedLoaded: new CustomEvent('selectedLoaded', { detail: { step: 'info' } }),
        formFieldValidationEvent: new CustomEvent('jims-wpcb-form-field-validation', {
            bubbles: true,
            detail: {
                field: null,
                status: false,
            },
        }),
    };

    /**
     *
     * @param BookingForm  bookingForm
     * @param mode
     */
    constructor(bookingForm, mode = 'default') {
        super(bookingForm, mode);
        this.bookingForm = bookingForm;

        this.containerCSSSelector = `.${this.bookingForm.prefixClass}__step.step.client-info`;
    }

    init() {
        super.init();
        this.HTMLElement = this.getContainer();
        this.addressConfirmationIsRequired
            = this.bookingForm.options.address_confirmation_enabled === '1'
            || this.bookingForm.options.address_confirmation_enabled === 'popup';

        this.addressFieldContainer = this.HTMLElement.querySelector('.form-address-field');

        // jims-wpcb__step__selected_services selected_services
        this.containers.selectedServices = this.getChild(`.${this.bookingForm.prefixClass}__step__selected_services ul`);

        // jims-wpcb__step__client_info client_info
        this.containers.clientInfo = this.getChild(`.${this.bookingForm.prefixClass}__step__client_information`);

        // jims-wpcb__step__client_information__location__confirm
        this.containers.addressConfirmation = this.getChildUnsafe(`.${this.bookingForm.prefixClass}__step__client_information__location__confirm`);

        // Pull DOM elements that are needed
        this.#elements.addressConfirmationInput = this.getChildUnsafe('input[name="location_confirm"]');
        this.#elements.addressConfirmationDialog = this.getChildUnsafe('.jims-wpcb__dialog.is-address-validation');
        this.#elements.addressInput = this.getChildUnsafe('input[name="address"]');
        this.#elements.map = this.getChildUnsafe('.is-address-validation .jims-wpcb__map');
        this.#elements.addressConfirmationDialogAddress = this.getChildUnsafe('.js-address-dialog-address');

        this.load();
    }

    setSelected() {
        this.services = this.bookingForm.getSelectedServices();
        this.divisionId = this.bookingForm.getSelectedDivision();

        this.renderSelectedServices();

        // dispatch event
        this.HTMLElement.dispatchEvent(this.#hooks.selectedLoaded);
    }

    visible() {
        super.visible();

        // show submit button
        this.bookingForm.setSubmitButtonVisibility(true);
        this.bookingForm.setSubmitButtonState(true);

        this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);

        // get selected services
        if (!this.hasPreselectedServices(this.bookingForm.options)) {
            this.setSelected();
        }
    }

    load() {
        const options = this.bookingForm.options;

        // get selected division if step active else use the default division
        this.bookingForm.setStepTitle('Book a Service:');
        this.bookingForm.setStepTitleVisibility(true);

        this.bookingForm.setPreviousButtonState(true);
        this.bookingForm.setNextButtonState(false, true);

        if (this.hasPreselectedServices(options)) {
            this.setSelected();
        }

        this.setupCountries();
        this.setupAddressAutocomplete();

        this.listen();
    }

    hasPreselectedServices(options) {
        return options.bookingFormServiceIds && options.bookingFormServiceIds.length > 0;
    }

    listen() {
        super.listen();

        this.enableValidation();

        this.HTMLElement.addEventListener('jims-wpcb-form-field-validation', (evt) => {
            this.dirty = this.bookingForm.isDirty();

            if (this.dirty || !this.addressConfirmationisChecked()) {
                this.bookingForm.setSubmitButtonState(true);
            }
            else {
                this.bookingForm.setSubmitButtonState(false);
            }

            Object.keys(this.bookingForm.formFieldValidationStatus).forEach((name) => {
                let field = this.HTMLElement.querySelector(`[name="${name}"]`);
                if (this.bookingForm.formFieldValidationStatus[name]) {
                    field.classList.remove('error');
                }
                else {
                    field.classList.add('error');
                }
            });
        });

        this.HTMLElement.addEventListener('keyup', (evt) => {
            if (evt.target.nodeName === 'INPUT') {
                // if classList contains phone, prevent user using letter keys
                if (evt.target.classList.contains('phone') || evt.target.type === 'tel') {
                    evt.target.value = evt.target.value.replace(/[^0-9]/g, '');
                }
            }
        });

        if (this.#elements.addressConfirmationDialog) {
            // Monitor the close of the address confirmation dialog
            this.#elements.addressConfirmationDialog.addEventListener('close', () => {
                if (this.#elements.addressConfirmationDialog.returnValue === 'yes') {
                    this.#elements.addressConfirmationInput.checked = true;
                }
                else {
                    this.#elements.addressConfirmationInput.checked = false;
                    this.#elements.addressInput.focus();
                }

                // Remove the map as its no longer needed. Instantiating the map twice
                this.leafletInstance.remove();

                // Becuase the checkbox is hidden, the dirty check event
                // listener is not firing, this handles that case
                this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);
            });

            const controls = this.#elements.addressConfirmationDialog.querySelectorAll('.jims-wpcb__dialog-control');

            // Since the outer layer of WPCB is a form, we cant nest a form in the dialog. This means we cant use the
            // native returnValue of the form. This adds in a returnValue matching the button which can then be used
            // to make decisions upon
            controls.forEach((control) => {
                control.addEventListener('click', (event) => {
                    // Becuase of the dirty event listeners in use, any click on
                    // a button will result in the form being submitted
                    // prematurely. The following line will prevent the event
                    // (button click) from bubbling to the form.
                    // event.preventDefault();

                    // Close the dialog
                    this.#elements.addressConfirmationDialog.close(control.value);
                });
            });
        }

        this.containers.addressConfirmation?.querySelector('input').addEventListener('change', (evt) => {
            this.userAddressConfirmation = evt.target.checked;
            this.bookingForm.formFieldValidationStatus[this.containers.addressConfirmation.querySelector('input').name] = this.userAddressConfirmation;
            this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);
        });

        // Observer for form class changes, to disable the navigation buttons if the form is offline
        const formObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (
                    mutation.type !== 'attributes'
                    || mutation.attributeName !== 'class'
                ) {
                    return;
                }

                if (this.bookingForm.formElement.classList.contains('offline')) {
                    this.bookingForm.formElement.querySelector(`.${this.bookingForm.prefixClass}-form__footer`).classList.add('deactivated');
                }
                else {
                    this.bookingForm.formElement.querySelector(`.${this.bookingForm.prefixClass}-form__footer`).classList.remove('deactivated');
                }
            });
        });

        formObserver.observe(this.bookingForm.formElement, {
            attributes: true,
        });
    }

    reset() {
        this.toggleAddressConfirmation(true);
        this.triggerEvent('formFieldValidationEvent');
    }

    renderSelectedServices() {
        this.containers.selectedServices.innerHTML = '';

        this.bookingForm.getSelectedServicesObjectList(this.services).then((services) => {
            services.forEach((service) => {
                if (this.services.includes(service.id)) {
                    let li = document.createElement('li');
                    li.innerHTML = `<span class="selected-service__name"><span class="checkmark"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 26 26"><path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z"></path></svg></span>${service.name}</span>`;
                    this.containers.selectedServices.appendChild(li);
                }
            });
        });
    }

    enableValidation() {
        this.addMultipleEventListener(this.HTMLElement, ['change'], (evt) => {
            let timer = setTimeout(() => {
                clearTimeout(timer);
                this.validate(evt);
            }, 500);
        });
    }

    getFieldType(field) {
        if (field.nodeName === 'TEXTAREA') {
            return 'textarea';
        }

        if (field.nodeName === 'SELECT') {
            return 'select';
        }

        return field.type;
    }

    validate(evt) {
        let PhoneValidator = new PhoneNumberValidator(this.bookingForm.options.rest_url);
        let countryCode = this.HTMLElement.querySelector('select[name="country"]')?.value;
        let field = evt.target;

        if (countryCode == null || countryCode.length === 0) {
            countryCode = this.bookingForm.options.country;
        }

        // check if field nodeName is INPUT | SELECT | TEXTAREA
        if (!['INPUT', 'TEXTAREA'].includes(field.nodeName)) {
            return;
        }

        if (field.name === 'address') {
            this.updateValidationStatus(field, false);
        }

        log.info('validation running');

        let addressValidationTime = null;

        // if target is required
        if (field.hasAttribute('required')) {
            field.classList.add('touched');
            // if field is empty
            if (field.type === 'tel') {
                PhoneValidator.validate(
                    countryCode,
                    field.value,
                ).then((response) => {
                    this.bookingForm.formFieldValidationStatus[field.name] = response.result;
                    this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);
                });
            }
            else {
                if (field.name === 'address' && ((this.bookingForm.options.google_address_validation && this.bookingForm.options?.google_address_validation === 0) || this.bookingForm.options?.google_address_validation === '1')) {
                    this.validateAddressField(field, countryCode);
                }
                else {
                    let status = FieldValidator.validate(this.getFieldType(field), field.value);
                    this.bookingForm.formFieldValidationStatus[field.name] = status;
                    this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);
                }
            }
        }
    }

    /**
     * Update the validation status of a field
     * @param field
     * @param result
     */
    updateValidationStatus(field, result) {
        // validate field name to not include square brackets
        if (field.name.includes('[') || field.name.includes(']')) {
            return;
        }

        this.bookingForm.formFieldValidationStatus[field.name] = result;
        this.HTMLElement.dispatchEvent(this.#hooks.formFieldValidationEvent);
    }

    /**
     *
     * @param field
     * @param response
     * @param messageElement
     * @param AddressValidator
     * @return {boolean}
     */
    handleAddressValidationResult(field, formattedAddress, response, messageElement, AddressValidator) {
        this.updateValidationStatus(field, response.result);
        field.value = formattedAddress;

        if (response.result === false && !response.hasOwnProperty('incomplete')) {
            let friendlyAddressParts = AddressValidator.getFriendlyAddressParts(response.data.address);

            let message = [
                'Malfomed address',
                friendlyAddressParts.length > 0 ? `Missing ${friendlyAddressParts.join(', ')}` : '',
                'Please enter a valid address',
            ].join('. ');

            messageElement.classList.remove('hidden');

            // assign message and remove line returns
            messageElement.innerHTML = message.replaceAll('\n', '');

            return false;
        }

        // The address is valid, so lets show the popup
        if (this.#elements.addressConfirmationDialog && this.#elements.addressConfirmationDialog.open === false) {
            // We must first show the dialog first and then setup the map
            // so that leaflet know what size to render the map at
            this.#elements.addressConfirmationDialog.showModal();
            this.#elements.addressConfirmationDialogAddress.innerHTML = this.formatAddressForDialog(response.data.address.formattedAddress, response.data.address.addressComponents);

            const latLng = [
                response.data.geocode.location.latitude,
                response.data.geocode.location.longitude,
            ];

            // Create the map
            this.leafletInstance = L.map(this.#elements.map, {
                center: latLng,
                zoom: 16,
            });

            // Add the tile layer
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(this.leafletInstance);

            // Add the marker
            L.marker(latLng, {
                icon: L.icon({
                    iconUrl: this.#elements.map.dataset.marker,
                    iconSize: [32, 40],
                    iconAnchor: [16, 40],
                }),
            }).addTo(this.leafletInstance);
        };
    }

    formatAddressForDialog(formattedAddress, components) {
        const country = this.extractTextFromAddressComponents(components, 'country');

        let toReplace = country === 'New Zealand'
            ? this.extractTextFromAddressComponents(components, 'locality')
            : this.extractTextFromAddressComponents(components, 'administrative_area_level_1');

        if (toReplace !== '') {
            return formattedAddress.replace(toReplace, `<span>${toReplace}</span>`);
        }

        return formattedAddress;
    }

    extractTextFromAddressComponents(components, type) {
        for (const component of components) {
            if (component.componentType === type) {
                return component.componentName.text;
            }
        }

        return '';
    }

    toggleLoaderOnAddress(open = true) {
        if (open) {
            this.toggleAddressConfirmation(false);
            this.addressFieldContainer.classList.add('field-loading');
        }
        else {
            this.toggleAddressConfirmation(true);
            this.addressFieldContainer.classList.remove('field-loading');
        }
    }

    /**
     * Toggle the address confirmation
     * @param open
     */
    toggleAddressConfirmation(open = true) {
        if (this.containers.addressConfirmation === null) return;

        if (open) {
            this.containers.addressConfirmation.classList.add('client_information__location__confirm-show');
        }
        else {
            this.containers.addressConfirmation.querySelector('input').checked = false;
            this.containers.addressConfirmation.classList.remove('client_information__location__confirm-show');
        }
    }

    validateAddressField(field, countryCode) {
        if (this.validateAddressFieldRunning === true) {
            return;
        }

        // Mark the address checkbox as not confirmed
        if (this.#elements.addressConfirmationInput) {
            this.#elements.addressConfirmationInput.checked = false;
        }

        // check if field is focused
        if (document.activeElement === field) {
            this.updateValidationStatus(field, false);
            return;
        }

        if (field.value.length < 5) {
            return;
        }

        this.toggleLoaderOnAddress(true);
        field.classList.remove('error');

        if (this.addressValidator === null) {
            this.addressValidator = new GoogleAddressValidation(this.bookingForm.options.google_api_key);
        }

        let messageElement = this.HTMLElement.querySelector('.address-error-message');

        // disable country select
        this.setCountrySelectState('disabled');

        // hide message
        messageElement.classList.add('hidden');
        messageElement.innerHTML = '';

        if (this.bookingForm.googleAutocompleteIsVisible()) {
            this.observeGoogleAutocomplete();
            this.updateValidationStatus(field, false);
            return false;
        }

        // Mark the address checkbox as not confirmed
        if (this.#elements.addressConfirmationInput) {
            this.#elements.addressConfirmationInput.checked = false;
        }

        this.validateAddressFieldRunning = true;

        // todo this function calls multiple times the google api
        // if the google address validation is passed check if the country is correct
        this.bookingForm.getAddressObject(field.value, countryCode).then((addressObject) => {
            if (addressObject.exception) {
                // field.classList.add('error');
                messageElement.classList.remove('hidden');
                messageElement.innerHTML = 'Please enter a valid address';

                this.toggleLoaderOnAddress(false);
                this.updateValidationStatus(field, false);
                return false;
            }

            let calculatedCountryCode = addressObject.country.length > 3
                ? this.bookingForm.countriesCodes.getCountryCode(addressObject.country)
                : addressObject.country;

            if (countryCode !== calculatedCountryCode) {
                messageElement.classList.remove('hidden');

                let countryName = this.bookingForm.countriesCodes.getCountryName(countryCode);
                let message = countryName
                    ? `Please enter a valid address for ${countryName}`
                    : `Please enter a valid address for selected country`;

                // assign message and remove line returns
                messageElement.innerHTML = message;

                this.toggleLoaderOnAddress(false);
                this.updateValidationStatus(field, false);
            }
            else {
                // todo remove the multiple calls to google api
                let formattedAddress = this.addressValidator.formatAddress(addressObject);

                this.addressValidator.validateAddress({
                    address: {
                        regionCode: countryCode,
                        addressLines: [formattedAddress],
                    },
                }).then((response) => {
                    this.handleAddressValidationResult(field, formattedAddress, response, messageElement, this.addressValidator);
                    this.toggleLoaderOnAddress(false);
                }).finally(() => {
                    this.setCountrySelectState('enabled');
                    this.triggerEvent('formFieldValidationEvent');
                    this.validateAddressFieldRunning = false;
                });
            }
        }).catch((error) => {
            messageElement.classList.remove('hidden');
            messageElement.innerHTML = 'Please enter a valid address';
            log.info(error);

            this.toggleLoaderOnAddress(false);
            this.updateValidationStatus(field, false);
            this.validateAddressFieldRunning = false;
        }).finally(() => {
            this.setCountrySelectState('enabled');
            this.validateAddressFieldRunning = false;
        });
    }

    /**
         * @return
         */
    observeGoogleAutocomplete() {
        if (!this.bookingForm.googleAutoCompleteActive()) return;

        let observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.target.style.display === 'none') {
                    this.HTMLElement.querySelector('form [name=address]').dispatchEvent(new Event('change', {
                        cancelable: true,
                        bubbles: true,
                    }));
                }
            });
        });

        observer.observe(document.querySelector('.pac-container'), {
            attributes: true,
            attributeFilter: ['style'],
        });
    }

    setupCountries() {
        if (this.countriesLoaded) return;

        let http = new HttpRequest(this.bookingForm.options.rest_url);
        let countriesSelect = this.HTMLElement.querySelector('select[name="country"]');
        const config = this.bookingForm.options;

        if (countriesSelect === null) { return false; }

        let countriesResponse = [];
        http.get(this.bookingForm.endpoints.countries).then((response) => {
            countriesResponse = response;
        }).catch((error) => {
            log.error(error);
        }).finally(() => {
            if (countriesResponse.hasOwnProperty('length') && countriesResponse.length > 0) {
                this.bookingForm.countries = countriesResponse;
                countriesSelect.innerHTML = '';
                this.bookingForm.countries.forEach((country) => {
                    let option = document.createElement('option');
                    option.value = country.symbol;
                    option.innerText = country.name;

                    if (config.country && country.symbol === config.country) {
                        option.setAttribute('selected', 'selected');
                        this.setFlag(country.symbol);
                    }

                    countriesSelect.appendChild(option);
                });

                if (this.bookingForm.countries.length > 0) {
                    this.countriesLoaded = true;
                }
            }
        });

        countriesSelect.addEventListener('change', (evt) => {
            this.setFlag(evt.target.value);
            if (this.bookingForm.useGoogleAutocomplete) {
                this.GoogleAutocomplete.setCountry(evt.target.value);
            }

            // revalidate address field
            let addressField = this.HTMLElement.querySelector('input[name="address"]');
            if (addressField.value.length > 5) {
                const e = new Event('change', {
                    bubbles: true,
                    cancelable: true,
                });
                addressField.dispatchEvent(e);
            }
        });
    }

    /**
         * Requests address autocomplete suggestions from findajim service
         * @param address
         * @param addressInput
         */
    getAddressSuggestions(address) {
        const httpRequest = new HttpRequest(this.bookingForm.options.rest_url);
        httpRequest.post(this.bookingForm.endpoints.address, {
            address: address,
            country_code: this.HTMLElement.querySelector('select[name="country"]').value,
        }).then((response) => {
            this.addressSuggestions = response;
            this.setAddressSuggestions();
        });
    }

    /**
         * Adds options to auto-complete for address
         */
    setAddressSuggestions() {
        const autocompleteElementContainer = this.HTMLElement.querySelector(`.${this.bookingForm.prefixClass}__autocomplete`);
        autocompleteElementContainer.innerHTML = '';
        autocompleteElementContainer.classList.add('open');

        // populate datalist with suggestions
        this.addressSuggestions.forEach((address) => {
            let autocompleteElement = new AddressAutoCompleteResult(
                address,
                this.bookingForm.prefixClass,
                this.HTMLElement.querySelector('input[name="address"]'),
                autocompleteElementContainer,
            );
            autocompleteElementContainer.append(autocompleteElement.render());
        });
    }

    setFlag(countryCode) {
        let countryImage = countryCode.toLowerCase();
        this.HTMLElement.querySelector('.icon-country img').setAttribute('src', `${this.bookingForm.options.wpcb_url}build/img/${countryImage}.png`);
    }

    setCountrySelectState(state = 'disabled') {
        let countrySelect = this.HTMLElement.querySelector('select[name="country"]');

        if (countrySelect === null) return;

        if (state === 'enabled') {
            countrySelect.removeAttribute('disabled');
            return;
        }

        countrySelect.setAttribute('disabled', 'disabled');
    }

    /**
         * Refreshes the address suggestions
         */
    setupAddressAutocomplete() {
        const addressInput = this.HTMLElement.querySelector('input[name="address"]');

        if (this.bookingForm.useGoogleAutocomplete) {
            this.GoogleAutocomplete = new GoogleAutocomplete(
                addressInput,
                this.HTMLElement,
                {
                    countryCode: this.bookingForm.options.country,
                },
                this.bookingForm.options.google_api_key,
            );
        }
        else {
            addressInput.addEventListener('keyup', AsyncHelper.debounce(() => {
                this.getAddressSuggestions(addressInput.value);
            }, 300));
        }
    }

    addressConfirmationisChecked() {
        return this.addressConfirmationIsRequired
            ? this.#elements.addressConfirmationInput.checked
            : true;
    }

    /**
         * @return {boolean}
         */
    getConnectionStatus() {
        !this.bookingForm.formElement.classList.contains('offline');
    }

    addMultipleEventListener(element, events, callback) {
        events.forEach((event) => {
            element.addEventListener(event, callback);
        });
    }

    triggerEvent(event) {
        this.HTMLElement.dispatchEvent(this.#hooks[event]);
    }
}

export default BookingFormInfoStep;
