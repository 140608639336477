

class Franchisee{
    //predifine all the properties
    business_id = null;
    agaccount_id = null;
    photo = null;
    display_name = null;
    company_name = null;
    user_name = null;
    suburb = null;
    state = null;
    country_id = null;
    main_person_id = null;
    is_jims_franchisee = null;
    default_franchisee = null;
    territory_holder = null;
    free_leads_remaining = null;
    only_available_previous_client = null;
    is_police_checked = null;
    is_work_guaranteed = null;
    licenses = null;
    available_date_time = null;
    star_rating = null;
    p_rating = null;
    trust_number = null;
    call_back_message = null;
    call_back_period = null;
    star_rating_count_one = null;
    star_rating_count_two = null;
    star_rating_count_three = null;
    star_rating_count_four = null;
    star_rating_count_five = null;
    search_entry_id = null;
    ppc_info_included = null;
    ppc_amount_in_cents = null;
    effective_max_bid_in_cents = null;
    minimum_bid_override = null;
    distance = null;
    geocode_x = null;
    geocode_y = null;
    tie_breaker = null;
    display_distance = null;
    package_free_leads = null;
    is_gold_contractor = null;
    same_day_service = null;
    direct_transfer_from_cc = null;
    mobile = null;
    work_phone = null;
    home_phone = null;
    pl_insurance = null;
    id = null;
    date_created = null;
    date_modified = null;
    created_by_string = null;
    created_by_user_id = null;
    modified_by_string = null;
    modified_by_user_id = null;
    deleted = null;
    record_state = null;
    record_state_text = null;


    constructor(data) {
        this.business_id = data.business_id;
        this.agaccount_id = data.agaccount_id;
        this.photo = data.photo;
        this.display_name = data.display_name;
        this.company_name = data.company_name;
        this.user_name = data.user_name;
        this.suburb = data.suburb;
        this.state = data.state;
        this.country_id = data.country_id;
        this.main_person_id = data.main_person_id;
        this.is_jims_franchisee = data.is_jims_franchisee;
        this.default_franchisee = data.default_franchisee;
        this.territory_holder = data.territory_holder;
        this.free_leads_remaining = data.free_leads_remaining;
        this.only_available_previous_client = data.only_available_previous_client;
        this.is_police_checked = data.is_police_checked;
        this.is_work_guaranteed = data.is_work_guaranteed;
        this.licenses = data.licenses;
        this.available_date_time = data.available_date_time;
        this.star_rating = data.star_rating;
        this.p_rating = data.p_rating;
        this.trust_number = data.trust_number;
        this.call_back_message = data.call_back_message;
        this.call_back_period = data.call_back_period;
        this.star_rating_count_one = data.star_rating_count_one;
        this.star_rating_count_two = data.star_rating_count_two;
        this.star_rating_count_three = data.star_rating_count_three;
        this.star_rating_count_four = data.star_rating_count_four;
        this.star_rating_count_five = data.star_rating_count_five;
        this.search_entry_id = data.search_entry_id;
        this.ppc_info_included = data.ppc_info_included;
        this.ppc_amount_in_cents = data.ppc_amount_in_cents;
        this.effective_max_bid_in_cents = data.effective_max_bid_in_cents;
        this.minimum_bid_override = data.minimum_bid_override;
        this.distance = data.distance;
        this.geocode_x = data.geocode_x;
        this.geocode_y = data.geocode_y;
        this.tie_breaker = data.tie_breaker;
        this.display_distance = data.display_distance;
        this.package_free_leads = data.package_free_leads;
        this.is_gold_contractor = data.is_gold_contractor;
        this.same_day_service = data.same_day_service;
        this.direct_transfer_from_cc = data.direct_transfer_from_cc;
        this.mobile = data.mobile;
        this.work_phone = data.work_phone;
        this.home_phone = data.home_phone;
        this.pl_insurance = data.pl_insurance;
        this.id = data.id;
        this.date_created = data.date_created;
        this.date_modified = data.date_modified;
        this.created_by_string = data.created_by_string;
        this.created_by_user_id = data.created_by_user_id;
        this.modified_by_string = data.modified_by_string;
        this.modified_by_user_id = data.modified_by_user_id;
        this.deleted = data.deleted;
        this.record_state = data.record_state;
        this.record_state_text = data.record_state_text;
    }

    isContractor() {
        return this.is_jims_franchisee === false;
    }
}

export default Franchisee;