/**
 * @namespace Types
 * @type {Types.BookingRequest}
 */
class BookingRequest {

    firstname = '';

    lastname = '';

    email = '';

    phone = '';

    address = [];

    comments = '';

    businessName = '';

    services = [];

    constructor(firstname, lastname, email, phone, address, comments, businessName, services) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.phone = phone;
        this.address = address;
        this.comments = comments;
        this.businessName = businessName;
        this.services = services;
    }

    getProperties() {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phone: this.phone,
            address: this.address,
            comments: this.comments,
            businessName: this.businessName,
            services: this.services
        };
    }

    getFormData() {
        const formData = new FormData();
        formData.append('firstname', this.firstname);
        formData.append('lastname', this.lastname);
        formData.append('email', this.email);
        formData.append('phone', this.phone);
        formData.append('address', this.address);
        formData.append('comments', this.comments);
        formData.append('businessName', this.businessName);
        return formData;
    }

    getJSON() {
        return JSON.stringify(this.getProperties());
    }
}

export default BookingRequest;