/**
 * Created by Konstantinos on 2023/10/06.
 */

import log from "../services/log.mjs";

/**
 * @class Step
 * @classdesc An abstract class that represents a step in a workflow.
 */
class Step {

    name = '';

    /**
     * @type {string}
     */
    containerCSSSelector = '';
    /**
     * @type {BookingForm}
     */
    bookingForm = null;

    /**
     * @type {Object.<string, string>}
     */
    #endpoints = {};

    mode = 'default';

    /**
     * @type {null|HTMLElement}
     */
    HTMLElement = null;

    /**
     * @type {boolean}
     */
    initialized = false;

    /**
     * @type {boolean}
     */
    hidden = true;

    /**
     * @type {string}
     */
    constructor(BookingForm, mode = 'default') {
        this.bookingForm = BookingForm;
        this.mode = mode;
    }

    hide() {
        this.hidden = true;
        this.HTMLElement.classList.add('hidden');
    }

    show() {
        this.hidden = false;
        this.HTMLElement.classList.remove('hidden');

        if (this.initialized){
            this.visible();
        }
    }

    /**
     * This method initializes the step
     * however usually we need to create an override for this method
     */
    init() {
        //get the container, this statement is placed here because the container might not be available in the constructor
        this.HTMLElement = this.getContainer();
        console.log(this.HTMLElement);

        //set initialized to true
        this.initialized = true;
    }

    load(){};

    listen(){};

    visible() {
        log.info(`Step ${this.bookingForm.currentStep} is visible`);

        //disable the previous button if we are at the first step
        if( this.bookingForm.currentStep === 0 ){
            this.bookingForm.setPreviousButtonState(false);
        }
    }
    /**
     * Returns the container HTMLElement
     * @returns {HTMLElement}
     */
    getContainer() {
        if (this.containerCSSSelector === '' || this.bookingForm.formElement.querySelector(this.containerCSSSelector) === null) {
            throw new Error('containerClass is not defined or was not found in the DOM');
        }

        return this.getParentProperty('formElement').querySelector(this.containerCSSSelector);
    }

    getChild(selector){
         let child = this.HTMLElement.querySelector(selector);

         if(child === null){
             throw new Error(`Child with selector ${selector} was not found`);
         }

         return child;
    }

    getChildUnsafe(selector){
        return this.HTMLElement.querySelector(selector);
    }

    getParentProperty(propertyName) {
        if (this.bookingForm[propertyName] === undefined) {
            throw new Error(`Parent property ${propertyName} is not defined`);
        }

        return this.bookingForm[propertyName];
    }

}

export default Step;