/**
 * Created by Administrator on 2023/7/17.
 *
 */

const FieldValidator = new class {

    validate(type, value){
        return this[type](value);
    }

    text(value, min = 2, max = 1000){
        return !this.isEmpty(value) && value.length >= min && value.length <= max;
    }

    textarea(value, min = 2, max = 1000){
        return !this.isEmpty(value) && value.length >= min && value.length <= max;
    }

    number(value, min = 0, max = 1000){
        return !this.isEmpty(value) && value > min && value < max;
    }

    email(value){
        //change the following regex to accept also emails like this thomas.hill@adhesion.co.nz

        return !this.isEmpty(value) && /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
    }

    checkbox(value){
        return !value;
    }

    /**
     *
     * @param {string|number|null} value
     */
    isEmpty(value){
        return value == null || value == '';
    }
}

export {FieldValidator};