import HttpRequest from "./HttpRequest.mjs";
import Division from "./division.mjs";
import log from "../services/log.mjs";
import Step from "./step.mjs";

/**
 * BookingFormDivisionStep
 */
class BookingFormDivisionStep extends Step {

    name = 'division';

    /**
     * @type {{getDivisions: string}}
     */
    #endpoints = {
        getDivisions: 'jims-wpcb/v1/service/divisions'
    };

    /**
     * @type {Array}
     */
    divisions = [];

    /**
     *
     * @type {null|Division}
     */
    selected = null;

    divisionListContainerCSSSelector = '';

    /**
     * constructor
     * @param bookingForm {BookingForm}
     * @param mode {string} 'default' or 'icons' default mode shows the divisions as buttons, the icons mode shows the divisions as icons
     */
    constructor( bookingForm, mode = 'default' ) {
        super(bookingForm, mode);
        this.bookingForm = bookingForm;
        this.mode = mode;
        this.containerCSSSelector = `.${this.bookingForm.prefixClass}__step.division-selector`;
        this.divisionListContainerCSSSelector = `.${this.bookingForm.prefixClass}__division-selector__list`
    }

    init(){
        this.HTMLElement = this.getContainer();
        this.load();

        //listen for clicks
        this.listen();

        this.bookingForm.setNextButtonState(false);
        this.initialized = true;
    }

    /**
     * Listens for clicks on the division buttons
     */
    listen() {
        this.#enableSearch();

        this.HTMLElement.addEventListener('click', (event) => {
            if (event.target.classList.contains(`${this.bookingForm.prefixClass}__division-selector__item`)) {
                const divisionId = event.target.dataset.id;

                console.log(this.divisions);

                //unset all other divisions
                this.divisions.forEach((division) => {
                    if (division.id == divisionId) { // the == is because sometimes the divisionId is a string and sometimes it is a number
                        division.select();
                        this.bookingForm.setNextButtonState(true);
                        this.updateSelectedDivision(division);

                        //set the division id in the booking form
                        this.bookingForm.setSelectedDivision(division.id);
                    } else {
                        division.unselect();
                    }
                });
            } else {
                throw new Error('BookingFormDivisionStep: click event target is not a division');
            }
        });
    }

    visible() {
        super.visible();
        this.bookingForm.setNextButtonState(this.selected !== null);
    }

    /**
     * Loads the divisions from the API and creates the division objects and HTML elements
     */
    load() {
        this.bookingForm.setStepTitle('Select a division');
        this.bookingForm.loader.show();
        this.bookingForm.setFormNotification(this.bookingForm.messages.divisions_loading);
        this.bookingForm.setStepTitleVisibility(false);

        //initiate the http request
        let http = new HttpRequest( this.bookingForm.options.rest_url );

        let query = new URLSearchParams({
            'country_code': this.bookingForm.options.country,
        });

        //get the divisions
        http.get( `${this.#endpoints.getDivisions}?${query.toString()}` ).then( ( response) => {

            //render the divisions if the response is an array and has at least one item
            if ( Array.isArray(response ) && response.length > 0 ) {

                this.divisions = response.map(( division ) => {
                    if( this.bookingForm.options.show_only_divisions.length > 0 && !this.bookingForm.options.show_only_divisions.includes(division.id) ) {
                        return;
                    }

                    //create the division object
                    const DivisionObject = new Division(division.id, division.name, division.name.toLowerCase(), division.icon, this.bookingForm.prefixClass );

                    //add the division object to division step object
                    return DivisionObject;
                });

                //filter out the undefined divisions
                this.divisions = this.divisions.filter(division => division !== undefined);

                if(this.bookingForm.options.show_only_divisions.length > 0){
                    //order divisions by the order of the show_only_divisions array
                    this.divisions.sort((a, b) => {
                        return this.bookingForm.options.show_only_divisions.indexOf(a.id) - this.bookingForm.options.show_only_divisions.indexOf(b.id);
                    });
                }

                this.divisions.forEach((division) => {
                    //render the division
                    //append the division HTMLElement to the container
                    this.getDivisionListContainer().appendChild(division.HTMLElement );
                });

                //if divisions are loaded, hide the loader
                if ( this.divisions.length >= 1 ) {
                    this.bookingForm.loader.hide();
                }
            }

        }).catch( ( error ) => {
            /**
             * @var error {{code: number, message: string}}
             */
            log.error( error );
        });
    }

    getDivisionListContainer() {
        if (this.divisionListContainerCSSSelector === '' || document.querySelector(this.divisionListContainerCSSSelector) === null) {
            throw new Error('BookingFormDivisionStep: divisionListContainerCSSSelector is not set');
        }

        return this.HTMLElement.querySelector(this.divisionListContainerCSSSelector);
    }

    /**
     * Updates the selected division
     * @param DivisionObject {Division}
     */
    updateSelectedDivision( DivisionObject) {
        if (DivisionObject instanceof Division) {
            this.selected = DivisionObject;
        }
    }

    /**
     * Initialize search capabilities
     */
    #enableSearch() {
        this.seachbar = this.HTMLElement.querySelector('.search-bar');
        let handler = null;
        this.seachbar.addEventListener('keyup', (evt) => {
            //set timeout and cancel previous timeout
            handler = setTimeout(() => {
                clearTimeout(handler);
                this.#search(evt.target.value);
            }, 500);
        });
    }

    #search(keyword) {
        //hide all services
        this.divisions.forEach((division) => {
            division.hide();

            if (division.name.toLowerCase().includes(keyword.toLowerCase())) {
                division.show();
            }
        });
    }
}

export default BookingFormDivisionStep;