/**
 * Created by Konstantinos Tsatsarounos on 2023/7/11.
 *
 * This class validates Australian, New Zealand, Canadian, and United Kingdom phone numbers.
 */
import HttpRequest  from "../components/HttpRequest.mjs";

class PhoneNumberValidator {

    endpoint = 'jims-wpcb/v1/input-validation/phone';

    constructor(rest_url) {
        this.countryCode = "";
        this.phoneNumber = "";
        this.rest_url = rest_url;
    }

    /**
     * This method validates Australian, New Zealand, Canadian, and United Kingdom phone numbers.
     * @param {string} countryCode
     * @param {string} phoneNumber
     * @returns Promise
     */
    validate(countryCode, phoneNumber) {
        this.countryCode = countryCode;
        this.phoneNumber = phoneNumber;

        //remove non numeric characters
        this.removeNonNumericCharacters();

        let request = new HttpRequest(this.rest_url);

        //create url query string
        let query = new URLSearchParams();
        query.append('country', this.countryCode);
        query.append('number', this.phoneNumber);

        return request.get(`${this.endpoint}?${query.toString()}`);
    }

    removeNonNumericCharacters() {
        this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '');
    }
}

export default PhoneNumberValidator;