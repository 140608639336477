class ConnectionStatus {
    constructor() {
        this.online = navigator.onLine;
        this.network = navigator.connection;
        this.callbacks = [];
        window.addEventListener('online', this.updateOnlineStatus.bind(this));
        window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    }

    updateOnlineStatus() {
        this.online = navigator.onLine;
        this.callbacks.forEach(callback => callback(this.online, this.network));
    }

    addListener(callback) {
        this.callbacks.push(callback);
    }
}

export default ConnectionStatus;