class GoogleAutocomplete {
    constructor(element, parentFormElement, options, googleMapsApiKey) {
        this.element = element;
        this.options = options;
        this.initScript(googleMapsApiKey);
        this.country = options.countryCode.toLowerCase();
        this.parentFormElement = parentFormElement;
        this.autocomplete = null;
        this.address = null;
        this.place = null;
        this.placeChanged = null;
    }

    initScript(googleMapsApiKey) {
        if (this.element && !document.getElementById('google-maps')) {
            let script = document.createElement('script');
            script.id = 'google-maps';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&channel=3&libraries=places&language=en`;
            document.body.appendChild(script);

            script.onload = () => {
                this.init();
            }
        } else if (this.element && document.getElementById('google-maps') !== null) {
            document.getElementById('google-maps').addEventListener('load', () => {
                this.init();
            });
        }
    }

    init() {
        if (this.element) {
            this.autocomplete = new google.maps.places.Autocomplete(this.element, {
                types: ['geocode'],
                componentRestrictions: {country: this.country},
                language: 'en',
                fields: ['address_components', 'formatted_address', 'place_id'],
            });

            this.autocomplete.addListener( 'place_changed', this.onPlaceChanged.bind(this) );
        }
    }

    setCountry(country) {
        this.country = country;
        if (this.autocomplete) {
            this.onCountryChanged();
        }
    }

    onPlaceChanged(evt) {
        this.place = this.autocomplete.getPlace();
        this.address = this.place.formatted_address;
        this.placeChanged = true;

        this.element.dispatchEvent(new Event('change', {
            detail: {
                place: this.place,
                address: this.address
            },
            'bubbles': true,
            'cancelable': true
        }));
    }

    onCountryChanged() {
        this.autocomplete.setComponentRestrictions({country: this.country});
    }

    /**
     * Get countryCode from the select element named country
     * @returns string
     */
    getCountryFromDocument() {
        //get the select element named country
        let countryElement = this.parentFormElement.querySelector('select[name="country"]');

        countryElement.addEventListener('change', (e) => {
            this.setCountry(e.target.value);
        });
    }

    getPlace() {
        return this.place;
    }

    getAddress(composed = false) {
        return composed ? this.composeAddress() : this.address;
    }

    isPlaceChanged() {
        return this.placeChanged;
    }

    resetPlaceChanged() {
        this.placeChanged = false;
    }

    getFormattedPlace() {
        //the result should be and object with the following properties
        //street_number, street_name, suburb, state, postcode, country, full_address, lat, lng
        //get the data from this.place
        let result = {
            street_number: '',
            street_name: '',
            suburb: '',
            state: '',
            postcode: '',
            country: '',
            full_address: '',
            geocode_y: '',
            geocode_x: ''
        };
        let address_components = {};

        if (this.place) {
            result.full_address = this.place.formatted_address;
            result.geocode_y = this.place.geometry.location.lat();
            result.geocode_x = this.place.geometry.location.lng();

            address_components = this.getAddressComponents();
        }

        return {
            ...result,
            ...address_components
        };
    }

    getAddressComponents() {
        let address_components = this.place.address_components;

        let address = {
            street_number: '',
            street_name: '',
            suburb: '',
            state: '',
            country: '',
            postcode: '',
            unit_number: '',
        };

        for (let component of address_components) {
            if (component.types.includes('street_number')) {
                address.street_number = component.long_name;
            }

            if (component.types.includes('route')) {
                address.street_name = component.long_name;
            }

            if (component.types.includes('locality')) {
                address.suburb = component.long_name;
            }

            if (component.types.includes('administrative_area_level_1')) {
                address.state = component.short_name;
            }

            if (component.types.includes('country')) {
                address.country = component.long_name;
            }

            if (component.types.includes('postal_code')) {
                address.postcode = component.long_name;
            }

            if (component.types.includes('subpremise')) {
                address.unit_number = component.long_name;
            }

        }

        return address;
    }

    composeAddress() {
        //the format of the address should be
        //street_number street_name, suburb, state, postcode, country
        let address = this.getAddressComponents();
        let unit_number = address.unit_number ? `#${address.unit_number}, ` : '';
        return `${unit_number}${address.street_number} ${address.street_name}, ${address.suburb}, ${address.state}, ${address.postcode}, ${address.country}`;
    }
}

export default GoogleAutocomplete;