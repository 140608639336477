import './scss/front.scss';
import BookingForm from './js/components/booking-form.mjs';
import { RecognizedBrowser } from "sniffr"
import IframeCommunication from "./js/services/iframe-communication.mjs";

(function (w, d) {
    const iframe = d.querySelector('#cb-window');
    const embed = d.querySelector('div.wpcb-form__embedded > iframe');
    const cssSelector = getCssSelector();
    const iframeCommunication = new IframeCommunication();

    let insideIframe = iframeCommunication.hasIframe();
    let useModal = true;
    w.wpcb_logger = [];
    w.cbLink = '';
    w.bookingForms = {};
    let useGoogleAutoComplete = false;
    let browser = '';
    let OperatingSystem = '';

    if (RecognizedBrowser) {
        browser = RecognizedBrowser.browser.name;
        OperatingSystem = RecognizedBrowser.os.name;
    }

    console.log(`iframe: ${insideIframe ? 'true' : 'false'}`);
    console.log(`browser: ${browser}`);


    if (d.querySelector('.wpcb.wpcb-form__native')) {
        d.querySelectorAll('.wpcb.wpcb-form__native').forEach(function (form, counter) {
            let overrides = window.jcbForms && window.jcbForms[form.dataset.formId] ? window.jcbForms[form.dataset.formId] : {};

            //hide the form until determine if it is inside an iframe
            form.style.opacity = 0;

            //add browser and OS classes
            form.classList.add(browser, OperatingSystem);

            //check if the form is inside an iframe
            if (insideIframe === false || overrides?.iframes_allowed === '1') {
                useGoogleAutoComplete = w.jims_wpcb_variables.use_google_autocomplete === '1' && w.jims_wpcb_variables.google_api_key.length > 0;
                w.bookingForms[counter] = new BookingForm(form, w.jims_wpcb_variables, useGoogleAutoComplete, overrides);
            } else {
                form.innerHTML = '<div class="cb3-iframe-detected"><p>Embedding of a CB form inside an iframe is no longer supported. Please use a native CB form, or link to the appropriate division on the <span>http://cb3.jims.net</span> website.</p></div>';
            }

            //show the form
            form.style.opacity = 1;
        });
    }

    //disables the previous forms
    if ((w.jims_wpcb_variables.hasOwnProperty('cb3FormEnabled') && w.jims_wpcb_variables.cb3FormEnabled !== '0')
        || (!w.jims_wpcb_variables.hasOwnProperty('cb3FormEnabled') && w.bookingForms)) {
        //remove iframes if a native form is present
        iframe ? iframe.remove() : null;
        embed ? embed.remove() : null;

        //cancel events from previous forms
        return;
    }


    const CbUrlChangeEvent = new CustomEvent('cburlchange', {
        bubbles: true,
        detail: { data: () => w.wpcb_logger }
    });

    if (iframe) {
        iframe.src = iframe.dataset.src;
    }


    console.log(cssSelector)
    //check if in any case selector is empty
    if (cssSelector.length === 0) return;

    //listen for window load or hashchange
    addDefaultListeners();

    //listen for messages from iframe
    w.addEventListener('message', function (event) {
        try {
            var data = JSON.parse(event.data);
            var dataLayer = window.dataLayer || (window.dataLayer = []);
            var pagePath = data.data.page_path;

            console.log(data);
            useModal = false;

            if (data.event) {
                dataLayer.push({
                    event: data.event,
                    data: data
                });
            }
            w.wpcb_logger.push(data);

            if (embed) {
                setHashPath();
            } else {
                isModalActive() ? setHashPath() : null;
            }

        } catch (e) {
            window.console && window.console.log(event);
        }
    });

    // booking-success
    w.addEventListener('cburlchange', function (evt) {
        let lastState = w.wpcb_logger[w.wpcb_logger.length - 1].data;
        let redirectUrl = getSuccessRedirectUrl();

        if (lastState.page_path === '/booking-success' && redirectUrl.length > 0) {
            w.location.replace(getSuccessRedirectUrl());
        }
    });

    //set functionality for the button which open the modal
    let button = document.querySelector('.jims-modal');

    if (button) {
        button.addEventListener('click', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            modal_toggle();
            return false;
        });
    }

    document.querySelector('.jims-cb-modal').addEventListener('click', function (evt) {
        if (evt.target.classList.contains('close__control')) {
            modal_toggle();
        }

        if (evt.target.closest('div').classList.contains('jims-cb-modal') && !evt.target.classList.contains('inner')) {
            modal_toggle();
        }
    });


    function modal_toggle() {
        let modal = document.querySelector('.jims-cb-modal');
        modal.classList.toggle('hidden');
        isModalActive() ? setHashPath() : setHashPath('');
    }


    function setHashPath() {
        if (arguments.length && arguments[0].length > 0) {
            window.location.hash = arguments[0];
            setBrowserHistoryState();
            return;
        }

        if (w.wpcb_logger.length > 0) {
            let lastState = w.wpcb_logger[w.wpcb_logger.length - 1].data;
            window.location.hash = lastState.page_path;

            window.dispatchEvent(CbUrlChangeEvent);
            setBrowserHistoryState();
        }
    }

    /**
     *
     */
    function setBrowserHistoryState() {
        const url = new URL(window.location);

        try {
            if (window.navigation) {
                window.history.pushState({}, '', url);
            } else {
                window.history.pushState({}, '', url);
            }
        } catch (e) {
            console.debug(e);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    function isModalActive() {
        let modal = document.querySelector('.jims-cb-modal');
        return !modal.classList.contains('hidden');
    }

    /**
     *
     * @returns {string|*}
     */
    function getCssSelector() {
        return typeof jims_wpcb_variables.cssSelector === 'undefined'
            || jims_wpcb_variables.embeddedFormOptions.cssSelector === null
            || (jims_wpcb_variables.embeddedFormOptions.cssSelector.hasOwnProperty('length') && jims_wpcb_variables.embeddedFormOptions.cssSelector.length === 0)
            ? '#jims' : jims_wpcb_variables.cssSelector;
    }

    /**
     *
     * @returns {string|*}
     */
    function getSuccessRedirectUrl() {
        return typeof jims_wpcb_variables.embeddedFormOptions.redirect_success_url === 'undefined'
            || jims_wpcb_variables.embeddedFormOptions.redirect_success_url === null
            || (jims_wpcb_variables.embeddedFormOptions.redirect_success_url.hasOwnProperty('length') && jims_wpcb_variables.embeddedFormOptions.redirect_success_url.length === 0)
            ? '' : jims_wpcb_variables.embeddedFormOptions.redirect_success_url;
    }

    /**
     * Update event handlers if iframe is not loader of has errors
     */
    function addDefaultListeners() {
        getCBLink().then((result) => {
            if (result.hasOwnProperty('status') && result.status !== 'success') {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status);
                return;
            } else {
                w.cbLink = result.cb_link;
            }
            w.addEventListener('load', reactToLocationHash);
            w.addEventListener('hashchange', reactToLocationHash);
        });
    }

    /**
     * If location hash is equal to cssSelector
     * decides what action should take
     * open modal or redirect, depending if the iframe
     * has sent a message or not
     */
    function reactToLocationHash() {
        if (w.location.hash === cssSelector) {

            if (window.jims_wpcb_variables.embeddedFormOptions.redirectOnly === "1") {
                redirect();
                return;
            }

            if (embed === null) {
                useModal ? modal_toggle() : redirect();
            }
        }
    }

    /**
     * Redirects to cb location
     */
    function redirect() {
        let BookingFormLink = w.cbLink.length > 0 ? w.cbLink : getCBLinkFallback();

        console.log(BookingFormLink);

        if (w.jims_wpcb_variables) {
            w.location.replace(`${BookingFormLink}`);
        }
    }

    async function getCBLink() {
        if (window.jims_wpcb_variables) {
            return await fetchData(`${window.jims_wpcb_variables.home_url}/wp-json/jims-wpcb/v1/customer-booking/cb-link`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
        }
    }

    /**
     * Returns the link to the cb page
     * @returns {string}
     */
    function getCBLinkFallback() {

        let trackingArr = [
            `DivisionId=${window.jims_wpcb_variables.embeddedFormOptions.DivisionId}`,
        ];

        if (window.jims_wpcb_variables.embeddedFormOptions.gtmId && window.jims_wpcb_variables.embeddedFormOptions.gtmId.length > 0) {
            trackingArr.push(`gtmId=${window.jims_wpcb_variables.embeddedFormOptions.gtmId}`);
        }

        if (window.jims_wpcb_variables.embeddedFormOptions.gatId && window.jims_wpcb_variables.embeddedFormOptions.gatId.length > 0) {
            trackingArr.push(`gatId=${window.jims_wpcb_variables.embeddedFormOptions.gatId}`);
        }


        let propertiesArr = [
            `countryCode=${window.jims_wpcb_variables.embeddedFormOptions.countryCode}`,
            `jimsProperty=${window.jims_wpcb_variables.embeddedFormOptions.jimsProperty}`,
            `primaryColor=${window.jims_wpcb_variables.embeddedFormOptions.primaryColor}`,
            `textColor=${window.jims_wpcb_variables.embeddedFormOptions.textColor}`,
            `logoUrl=${window.jims_wpcb_variables.embeddedFormOptions.logoUrl}`,
        ];

        trackingArr = trackingArr.concat(propertiesArr);

        let properties = trackingArr.join('&');

        return `${window.jims_wpcb_variables.embeddedFormOptions.http_schema}://${window.jims_wpcb_variables.embeddedFormOptions.domain}/?${properties}`;
    }

    /**
     * Fetches data from the server
     * @param url
     * @param options
     * @returns {Promise<any>}
     */
    async function fetchData(url, options = {}) {
        return await fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }

}(window, document));
