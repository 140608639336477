/**
 * a factory class for booking form step
 *
 * Booking Form Step
 * @module components/booking-form-step
 * @version 1.0.0
 */
import {StepDecorator} from "../decorators/booking-form.mjs";
import BookingFormDivisionStep from "./booking-form-division-step.mjs";
import BookingFormServiceStep from "./booking-form-services-step.mjs";
import BookingFormInfoStep from "./booking-form-info-step.mjs";
import BookingFormAddressStep from "./booking-form-address-step.mjs";

class BookingFormStepGenerator {

    /**
     *
     * @type {{parent: null, component: string, isCurrent: boolean, isVisited: boolean, isLastStep: boolean, isSkipped: boolean, name: string, isFirstStep: boolean, selector: string, isDisabled: boolean, isOptional: boolean, isHidden: boolean}}
     */
    step_props = {
        "name": '',
        "component": '',
        "parent": null,
        "isLastStep": false,
        "isFirstStep": false,
        "isCurrent": false,
        "isDisabled": false,
        "isHidden": false,
        "isOptional": false,
        "isSkipped": false,
        "isVisited": false,
        "isValid": false,
    }

    /**
     * @type {string}
     * @type {null}
     */
    $object = null;

    $steps = {};

    constructor() {
        // register default steps here
        this.steps = {
            'divisions': BookingFormDivisionStep,
            'services': BookingFormServiceStep,
            'address': BookingFormAddressStep,
            'info': BookingFormInfoStep,
        };
    }

    createStep(name, component, parent) {
        //check if form element exists
        if (parent) {
            let props = this.step_props;
            props.name = name;
            props.component = component;
            props.parent = parent;

            return StepDecorator(new this.registeredSteps[name](parent), props);
        }
    }

    get registeredSteps() {
        return this.steps;
    }

    addStep(name, classObject) {
        this.steps[name] = classObject;
    }

}

export default BookingFormStepGenerator;