/**
 * Created by Administrator on 2023/09/29.
 */

/**
 * @class Division
 * @description
 * @property {number} id
 * @property {string} name
 * @property {string} friendlyName
 * @property {string} icon
 * @property {boolean} selected
 * @property {HTMLElement} HTMLElement
 */
class Division {

    /**
     * @type {number}
     */
    id = 0;

    /**
     * @type {string}
     */
    name = '';


    /**
     * @type {string}
     */
    friendlyName = '';

    /**
     * @type {string}
     */
    icon = '';

    /**
     * @type {string}
     */
    CSSClassPrefix = '';


    /**
     * @type {boolean}
     */
    #selected = false;

    /**
     * @type {HTMLElement}
     */
    HTMLElement = null;

    /**
     *
     * @param id
     * @param name
     * @param friendlyName
     * @param icon
     * @param CSSClassPrefix
     */
    constructor(id, name, friendlyName, icon, CSSClassPrefix = '') {
        this.id = id;
        this.name = name;
        this.friendlyName = name;
        this.icon = icon;
        this.mode = icon.length > 0 ? 'withIcon' : 'default';
        this.CSSClassPrefix = CSSClassPrefix;

        this.#createHTMLElement();
    }

    select() {
        this.#selected = true;
        this.HTMLElement.classList.add('selected');
    }

    unselect() {
        this.#selected = false;
        this.HTMLElement.classList.remove('selected');
    }

    isSelected() {
        return this.#selected;
    }

    hide() {
        this.HTMLElement.style.display = 'none';
    }

    show() {
        this.HTMLElement.style.display = 'grid';
    }

    /**
     *
     * @return {*}
     */
    #createHTMLElement() {
        const element = document.createElement('div');
        element.classList.add(`${this.CSSClassPrefix}__division-selector__item`);

        if (this.mode === 'default'){
            element.classList.add(`no-image`);
        }

        element.setAttribute('data-id', this.id);
        element.setAttribute('data-name', this.name);
        element.innerHTML = this.#getTemplate(this.CSSClassPrefix, this.icon, this.friendlyName, this.mode);
        this.HTMLElement = element;
        return element;
    }

    /**
     * @param {number} id
     */
    set id(id) {
        this.checkProperty('id', id);
        this.id = id;
    }

    /**
     * @param {string} name
     */
    set name(name) {
        this.checkProperty('name', name);
        this.name = name;
    }

    /**
     * @param {string} friendlyName
     */
    set friendlyName(friendlyName) {
        this.checkProperty('friendlyName', friendlyName);
        this.friendlyName = friendlyName;
    }

    /**
     * @param {string} icon
     */
    set icon(icon) {
        this.checkProperty('icon', icon);
        this.icon = icon;
    }

    /**
     * @param name {string}
     * @param value {string|number|boolean}
     */
    checkProperty(name, value) {
        if (this[name] && !this.isEmptyOfNull(value)) {
            this[name] = value;
        } else {
            throw new Error('Division property is not exist or value is null');
        }
    }

    /**
     * @param {string} value
     * @returns {boolean}
     */
    isEmptyOfNull(value) {
        return value === null || value === undefined || value === '';
    }

    /**
     * @returns {string}
     */
    #getTemplate(prefixClass = '', iconPath = '', title = '', mode = 'default') {
        return {
            default: `<span class="${prefixClass}__division-selector__item-title">${title}</span>`,
            withIcon: `<img src="${iconPath}" class="${prefixClass}__division-selector__item-image" alt="${title}">
            <span class="${prefixClass}__division-selector__item-title">${title}</span>`
        }[mode];
    };
}

export default Division;