/**
 * Jims Services
 */

import HttpRequest  from "../components/HttpRequest.mjs";
/**
 *
 * This class is used to handle the api requests for jims services custom order
 * and category groupings.
 */
class JimsServices {

    rest_url = '';

    division_id = 0;

    countryCode = 'AU';

    routes = {
        divisions: 'jims-wpcb/v1/service/divisions',
        division_options: 'jims-wpcb/v1/service/division/options',
        countries: 'jims-wpcb/v1/service/countries',
        services: 'jims-wpcb/v1/service/division/services',
        order: 'jims-wpcb/v1/customize/service-order',
        categories: 'jims-wpcb/v1/customize/service-groups',
        descriptions: 'jims-wpcb/v1/customize/service-descriptions',
        options: 'jims-wpcb/v1/customize/options',
        delete_caches: 'jims-wpcb/v1/options/clear-transients'
    }

    constructor(base_url, division_id, country_code = 'AU') {
        this.rest_url = base_url;
        this.division_id = division_id;
        this.countryCode = country_code;

        this.request = new HttpRequest(this.rest_url);
    }

    getDivisionList() {
        return this.request.get(this.routes.divisions);
    }

    getDivisionOptions(division_id = 0) {

        let queryString = new URLSearchParams({
            'division_id': division_id !==0 ?  division_id : this.division_id
        });

        return this.request.get(`${this.routes.division_options}?${queryString.toString()}`);
    }

    getCountries() {
        return this.request.get(this.routes.countries);
    }

    getServiceList(division_id = 0, use_grouping = 0, country_code = 'AU') {
        if (this.division_id === 0) return false;

        //create query string
        let queryString = new URLSearchParams({
            'division_id': division_id !==0 ?  division_id : this.division_id,
            'use_grouping': use_grouping !==0 ? use_grouping : '0',
            'country_code': country_code !== '' && country_code.length === 2 ? this.countryCode : this.countryCode
        });

        return this.request.get(`${this.routes.services}?${queryString.toString()}`);
    }

    /**
     * @param data
     * @returns {Promise<*>}
     */
    setOrder(data) {
        return this.request.post(this.routes.order, data);
    }

    /**
     * @return {Promise<unknown>}
     */
    getOrder() {
        return this.request.get(this.routes.order);
    }

    /**
     * @param data
     * @return {Promise<unknown>}
     */
    setCategories(data) {
        return this.request.post(this.routes.categories, data);
    }

    /**
     * @return {Promise<unknown>}
     */
    getCategories() {
        return this.request.get(this.routes.categories);
    }

    setServiceDescriptions(data) {
        return this.request.post(this.routes.descriptions, data);
    }

    getServiceDescriptions() {
        return this.request.get(this.routes.descriptions);
    }

    setOptions(data) {
        this.deleteCaches().then((response) => {
            console.log(response);
        });
        return this.request.post(this.routes.options, data);
    }

    getOptions() {
        return this.request.get(this.routes.options);
    }

    deleteCaches() {
        return this.request.post(this.routes.delete_caches, {});
    }
}

export default JimsServices;