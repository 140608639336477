import log from "../services/log.mjs";

class HttpRequest {
    base = '';

    constructor(baseUrl) {
        this.base = baseUrl;
    }

    get(url) {
       return new Promise((resolve, reject) => {
           fetch(`${this.base}${url}`)
               .then(response => response.json())
               .then(data => {
                   resolve(data);
               })
               .catch(error => {
                   reject(error);
               });
       });
    }

    post(url, data) {
        return new Promise((resolve, reject) => {
            log.info(`${this.base}${url}`);
            fetch(`${this.base}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export default HttpRequest;