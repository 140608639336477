/**
 *
 */
import Service from "./service.mjs";

class ServiceGroup {

    /**
     * @type {Service[]}
     */
    services = [];

    /**
     * @type {string}
     */
    name = '';

    /**
     * @type {number}
     */
    gravity = 0;

    /**
     * @type {string}
     */
    description = '';

    /**
     * @type {HTMLElement}
     */
    element = null;

    /**
     *
     * @type {BookingForm}
     */
    parent = null;


    /**
     *
     * @param name {string}
     * @param gravity {number}
     * @param description {string}
     * @param parent {BookingForm}
     */
    constructor(name, gravity, description, parent) {
        this.name = name;
        this.gravity = gravity;
        this.parent = parent;
        this.description = '';

        if(description){
            this.description = description;
        }
    }

    createDomElement(){
        let element = document.createElement('div');
        element.classList.add(`${this.parent.prefixClass}-service-category`);
        element.innerHTML = this.getTemplate();

        this.element = element;

        if(this.services.length > 0){
            this.services.forEach(service => {
                this.element.querySelector('ul').appendChild(service.element);
            });
        }
    }

    addService(service) {
        this.services.push(service);
    }

    getTemplate() {
        return `
            <div class="minor-cont">
                <h3 class="${this.parent.prefixClass}-service-category-title">${this.name}</h3>
                <p class="${this.parent.prefixClass}-service-category-description">${this.description}</p>
            </div>
            <ul></ul>
        `;
    }
}

export default ServiceGroup;