class Loader {
    constructor(selector, bookingForm) {
        this.element = bookingForm.querySelector(selector);
    }

    hasClass(className) {
        return this.element.classList.contains(className);
    }

    modifyClass(classNames, action) {
        this.element.classList[action](...classNames);
    }

    show() {
        this.element.classList.add('open');
    }

    hide() {
        this.element.classList.remove('open');
    }

    addError() {
        this.element.classList.add('error');
    }

    removeError() {
        this.hide();
        this.element.classList.remove('error');
    }

    addSuccess() {
        this.element.classList.add('success');
    }

    removeSuccess() {
        this.hide();
        this.element.classList.remove('success');
    }

    addServiceLoader() {
        this.show();
        this.element.classList.add('service_load');
    }

    removeServiceLoader() {
        this.hide();
        this.element.classList.remove('service_load');
    }
}

export default Loader;
