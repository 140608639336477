class Cache {
    constructor() {
        this.storage = window.localStorage;
    }

    get(key) {
        return this.storage.getItem(key);
    }

    set(key, value) {
        this.storage.setItem(key, value);
    }

    setJson(key, value) {
        this.storage.setItem(key, JSON.stringify(value));
    }
    getJson(key) {
        return JSON.parse(this.storage.getItem(key));
    }

    has(key) {
        return this.storage.getItem(key) !== null;
    }

    createKeyFromObject(obj) {
        return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
    }
}

export default Cache;