import Step from "./step.mjs";

class BookingFormAddressStep extends Step{

    name = 'address';

    bookingForm = null;

    containers = {};

    HTMLElement = null

    constructor(bookingForm, mode='default'){
        super(bookingForm, mode);
        this.bookingForm = bookingForm;

        this.containerCSSSelector = `.${this.bookingForm.prefixClass}__step.step.address`;
    }

   init(){
        super.init();

       this.load();

       //listen for clicks
       this.listen();


        this.HTMLElement = this.getContainer();
   }

    visible() {
        super.visible();
        this.bookingForm.setSubmitButtonVisibility(false);
        this.bookingForm.setPreviousButtonState(true);

        //show the next button, remove hidden class and set the button state
        //todo fix this to be more clean
        this.bookingForm.setNextButtonState(true, true);
    }

   listen() {
       super.listen();
   }


   load(){
       super.load();
   }
}


export default BookingFormAddressStep;