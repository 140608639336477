/**
 *
 */

class IframeCommunication {
    /**
     * Checks if the page is loading inside an iframe
     */
    hasIframe() {
        if (window.location === null || window?.parent === null) {
            return false;
        }

        let result = false;
        try {
            result = window.location !== window?.parent?.location;
        } catch (e) {
            console.log(e);
        }

        return result;
    }
}

export default IframeCommunication;