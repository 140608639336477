/**
 * Google Analytics
 * this class assumes that the Google Analytics script is already loaded
 */
class GoogleAnalytics {
    constructor(window, throwError = false) {
        //check if window.gtag is defined
        if (typeof window.gtag === 'undefined') {
            //listen for the script to be loaded
            window.addEventListener('load', () => {
                if (typeof window.dataLayer === 'undefined') {
                    if (throwError) throw new Error('Google Analytics script is not loaded');

                    console.error('Google Analytics script is not loaded');
                } else {
                    this.createGtag(window);
                }
            });
        } else {
            this.createGtag(window);
        }
    }

    createGtag(window) {
        this.gtag = function () {
            window.dataLayer.push(arguments);
        };
    }

    /**
     *
     * @param category
     * @param action
     * @param label
     * @param value
     */
    sendEvent(category, action, label, value, extraData= {}) {
        let eventData = {
            ...extraData,
            'event_category': category,
            'event_label': label,
            'value': value
        };

        if (this.gtag) {
            this.gtag('event', action, eventData);
        }
        //this console.info is left on purpose
        console.info(`Google Analytics event sent: ${category}, ${action}, ${label}, ${value}, ${JSON.stringify(extraData)}`);
    }
}

export default GoogleAnalytics;