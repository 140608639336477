/**
 * Step Decorators
 */

/**
 *
 * @param step Object
 * @constructor
 */
export function StepDecorator(step, parent) {
    // if props is not an object, return the step
    if (typeof props !== 'object') {
        return step;
    }

    // loop through props and assign them to the step
    for (let prop in props) {
        if (props.hasOwnProperty(prop)) {
            //if prop already exists, skip it
            if (step.hasOwnProperty(prop)) {
                continue;
            }

            step[prop] = props[prop];
        }
    }

    return step;
}


