const log = new class {

    /**
     * Checks if the host is localhost, 127.0.0.1 or the domain name has .local in the end
     * @return boolean
     */
    isDev(){
        //get window host
        let host = window.location.host;

        //check if is local web server
        if(host === 'localhost' || host === '127.0.0.1' || host.endsWith('.local') || window.wpcbDebug === true){
            return true;
        }

        return false;
    }

    //create function for each console method and do console log only if isDev is true
    log(...args){
        if(this.isDev()){
            console.log(...args);
        }
    }

    warn(...args){
        if(this.isDev()){
            console.warn(...args);
        }
    }

    error(...args){
        if(this.isDev()){
            console.error(...args);
        }
    }

    info(...args){
        if(this.isDev()){
            console.info(...args);
        }
    }

    debug(...args){
        if(this.isDev()){
            console.debug(...args);
        }
    }

    group(...args){
        if(this.isDev()){
            console.group(...args);
        }
    }

    groupEnd(...args){
        if(this.isDev()){
            console.groupEnd(...args);
        }
    }
}

export default log;