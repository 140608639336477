class Service {

    selected = false;

    /**
     * @type {null|Step}
     */
    parent = null

    constructor(name, id, description = '', inGroup = false) {
        this.name = this.normalizeName(name);
        this.id = id;
        this.description = description;
        this.inGroup = inGroup;
        this.element = this.create();
        this.selectedElement = this.createSelected();

        this.element.addEventListener('mousedown', (evt) => {
           this.toggleSelected();
        });
    }

    setParentElement(step){
        this.parent = step;
    }

    update(id, name, checked){
        this.selected = checked;
        this.id = id;
        this.name = name;

        this.element.innerHTML = this.render();
        this.updateCheckbox(checked);
    }

    /**
     * Create the service element
     * @returns {*}
     */
    create() {
        const element = document.createElement('li');
        element.classList.add('service');
        element.innerHTML = this.render();

        return element;
    }

    /**
     * Create the selected service element
     * this is rendered in the third step of the form, with the finalized services
     * @returns {*}
     */
    createSelected() {
        const element = document.createElement('li');
        element.classList.add('service-id-' + this.id);
        element.classList.add('selected-service');
        element.innerHTML = this.render();

        return element;
    }

    render(){
      let description = this.inGroup ? `<span class="selected-services__description">${this.description}</span>` : '';

      return `
        <div>
            <label class="service__label">
                <input type="checkbox" name="services[]" value="${this.id}">
                <span class="service__name"><span class="service__checkbox">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 26 26"><path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z"></path></svg>
                </span>
                <span class="selected-services__title">${this.name}</span>
                ${description}
            </label>
        </div>`;

       //<span class="selected-services__description">${this.description}</span>
    }

    /**
     *
     */
    setSelected() {
        this.selected = true;
    }

    unselect() {
        this.selected = false;
    }

    toggleSelected() {
        this.selected = !this.selected;
        this.updateCheckbox(this.selected);

        console.log('toggle selected');

        if(this.parent.dispatchServiceStatusChangeEvent !== undefined){
            this.parent.dispatchServiceStatusChangeEvent(this.id, this.selected);
        }
    }

    updateCheckbox(selected){
        if (selected === false) {
            this.element.querySelector('input').checked = false;
            this.element.classList.remove('checked');
            return;
        }

        if (selected) {
            this.element.querySelector('input').checked = true;
            this.element.classList.add('checked');
        }
    }

    init() {
        console.log(this.name + ' initialized');
    }

    hide() {
        this.element.classList.add('hidden');
    }

    show() {
        this.element.classList.remove('hidden');
    }

    /**
     * Replace tabs with spaces
     */
    normalizeName(string) {
        return string.replace(/\t/g, ' ');
    }

}

export default Service;