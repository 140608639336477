/**
 * Created by Konstantinos Tsatsarounos on 3/11/23
 */
import HttpRequest from "../components/HttpRequest.mjs";
import Franchisee from "./franchisee.mjs";

/**
 * A wrapper for the Franchisee object
 *
 */
class FmsProvider {

    #endpoints = {
        'photo' : 'jims-wpcb/v1/customer-booking/url/fse-photo'
    };

    #requiredProperties = [
        'business_id',
        'agaccount_id',
        'pl_insurance',
        'call_back_message',
        'display_name',
        'star_rating',
        'is_jims_franchisee',
        'is_gold_contractor',
        'is_jims_franchisee',
        'is_police_checked',
        'is_work_guaranteed',
        'only_available_previous_client'
    ];

    #options = {};

    /**
     * @type {Franchisee}
     */
    provider = null;

    /**
     * @param {Object|Franchisee} provider
     * @param options
     */
    constructor(provider, options={}) {
        if (!this.validate(provider)) {
            throw new Error('Invalid provider object');
        }

        this.#options = options;

        //check if provider is a Franchisee object
        this.provider = provider instanceof Franchisee ? provider : new Franchisee(provider);
    }

    /**
     * @returns object of checks, ex: is police checked, work warranty
     */
    getChecks() {
        return {
            isPoliceChecked: this.provider.is_police_checked,
            isWorkWarranty: this.provider.is_work_guaranteed
        };
    }

    getMessage() {
        return this.provider.call_back_message;
    }

    getVideo() {
        throw new Error('Not implemented');
    }

    setPhoto(selector) {
        if(this.#options.siteUrl){
            let request = new HttpRequest(this.#options.siteUrl);
            let photo_id = this.provider.business_id;
            let is_contractor = this.provider.is_jims_franchisee ? 0 : 1;

            if(!this.provider.is_jims_franchisee && this.provider.agaccount_id){
                photo_id = this.provider.agaccount_id;
            }

            request.get(`${this.#endpoints.photo}?fse_business_id=${photo_id}&is_contractor=${is_contractor}`).then((response) => {
                let imageElements = document.querySelectorAll(selector);

                Object.values(imageElements).forEach((imageElement) => {
                  let defaultImage = imageElement.getAttribute('src');
                    console.log(defaultImage);
                  this.checkImageExists(response.url, (exists) => {
                      if (exists) {
                            imageElement.setAttribute('src', response.url);
                      } else {
                            imageElement.setAttribute('src', defaultImage);
                      }
                  });
                });
            });
        }
    }

    getName() {
        return this.provider.display_name;
    }

    getRating() {
        if (this.provider.star_rating === null || this.provider.star_rating === 0) {
            return 0;
        }

        let rating = parseFloat( this.provider.star_rating );

        return  Math.round(rating * 100) / 100;
    }

    /**
     * @returns {boolean}
     */
    isContractor() {
        return this.provider.isContractor();
    }

    isFranchisee() {
        return this.provider.is_jims_franchisee;
    }

    isGoldContractor() {
        return this.provider.is_gold_contractor && this.isContractor();
    }

    isAvailableForNewClients() {
        return !this.provider.only_available_previous_client;
    }

    validate(providerObject) {
        let result = true;

        //check if has the required properties
        this.#requiredProperties.forEach((property) => {
            if (!providerObject.hasOwnProperty(property)) {
                result = false;
            }
        });

        return result;
    }

    checkImageExists(imageUrl, callBack) {
        const img = new Image();
        img.src = imageUrl;

        if (img.complete) {
            callBack(true);
        } else {
            img.onload = () => {
                callBack(true);
            };

            img.onerror = () => {
                callBack(false);
            };
        }
    }
}

export default FmsProvider;