class AddressAutoCompleteResult {
    constructor(address_details, prefixClass, target, autocompleteContainer) {
        // assign the address details to the object
        this.address_details = address_details;
        this.prefixClass = prefixClass;
        this.target = target;
        this.autoCompleteContainer = autocompleteContainer;
    }

    /**
     * Render the address details
     * @returns {string}
     */
    render() {
        this.element = document.createElement('div');
        this.element.classList.add(`${this.prefixClass}__autocomplete-result`);
        this.element.innerHTML = this.template();
        this.events();


        return this.element;
    }

    events() {
        this.element.addEventListener('click', (evt) => {
            evt.preventDefault();
            this.target.value = this.address_details.full_address;
            this.autoCompleteContainer.classList.remove('open');
            return false;
        });
    }

    template() {
        return `<span class="autocomplete-result-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><circle cx="128" cy="104" r="32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg></span>
                    <span class="autocomplete-result-location-name">${this.address_details.full_address}</span>`;
    }
}

export default AddressAutoCompleteResult;