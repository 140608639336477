import HttpRequest from "./HttpRequest.mjs";
import log from "../services/log.mjs";
import Step from "./step.mjs";
import BookingFormDivisionStep from "./booking-form-division-step.mjs";
import Service from "./service.mjs";
import ServiceGroup from "./service-group.mjs";
import JimsServices from "../services/jims-services.mjs";

/**
 * Booking Form Services Step
 * @module components/booking-form-services-step
 * @version 1.0.0
 */
class BookingFormServiceStep extends Step {

    name = 'services';

    #endpoints = {
        services: 'jims-wpcb/v1/service/division/services',
    };

    /**
     * Service[]
     * @type {[]}
     */
    services = [];

    /**
     * ServiceGroup[]
     * @type {[]}
     */
    groups = [];

    selected = [];

    division = null;

    #divisionUpdated = false;

    #memory = {
        servicesResponse: {}
    };

    /**
     *
     * @type JimsServices
     */
    #JmsServices = {};

    /**
     * Custom events
     * @type {{servicesLoaded: CustomEvent<{services: []}>}}
     */
    #hooks = {
        servicesLoaded: new CustomEvent('servicesLoaded', {detail: {services: this.services}}),
        serviceStatusChange: new CustomEvent('serviceStatusChange', {detail: {status: null, id: null}})
    };

    constructor(bookingForm, mode = 'default') {
        super(bookingForm, mode);
        this.#JmsServices = new JimsServices(bookingForm.options.rest_url, bookingForm.options.bookingFormDivisionId);

        //{{prefix_class}}__step step services
        this.containerCSSSelector = `.${this.bookingForm.prefixClass}__step.step.services`;

        //define the service list container
        this.serviceListContainerCSSSelector = this.#isServiceGroupingEnabled(this.bookingForm)
            ? `.${this.bookingForm.prefixClass}-service-category-container`
            : `.${this.bookingForm.prefixClass}-service__results.results ul`;
    }

    init() {
        this.HTMLElement = this.getContainer();
        this.load();

        //listen for clicks
        this.listen();

        this.initialized = true;
    }

    visible() {
        super.visible();
        this.bookingForm.setSubmitButtonVisibility(false);

        if (this.bookingForm.steps && this.bookingForm.currentStep === 0) {
            this.bookingForm.setPreviousButtonState(false);
        } else {
            this.bookingForm.setPreviousButtonState(true);
        }

        //show the next button, remove hidden class and set the button state
        //todo fix this to be more clean
        this.bookingForm.setNextButtonState(true, true);
        this.bookingForm.setNextButtonState(this.selected.length > 0);
    }

    listen() {
        super.listen();

        this.#enableSearch();

        this.HTMLElement.addEventListener('serviceStatusChange', (event) => {
            this.selected = event.detail.status === true
                ? this.addSelected(this.selected, event.detail.id)
                : this.removeFromSelected(this.selected, event.detail.id);

            this.bookingForm.setSelectedServices(this.selected);

            this.bookingForm.setNextButtonState(this.selected.length > 0);
        });
    }

    dispatchServiceStatusChangeEvent(serviceId, status = null) {
        this.#hooks.serviceStatusChange.detail.status = status;
        this.#hooks.serviceStatusChange.detail.id = serviceId;

        this.HTMLElement.dispatchEvent(this.#hooks.serviceStatusChange);
    }

    load() {
        //set loader for services
        this.bookingForm.setFormNotification(this.bookingForm.messages.services_loading);
        this.bookingForm.loader.show();
        this.bookingForm.setStepTitleVisibility(false);

        //get the service list container
        const serviceListContainer = this.HTMLElement.querySelector(this.serviceListContainerCSSSelector);

        //if the service list container is null, avoid loading the services
        if (serviceListContainer === null) {
            this.bookingForm.loader.removeServiceLoader();

            //todo: show error message if container is null
            throw new Error('Service list container is null');

            if (this.bookingForm.options.bookingFormServiceIds.length === 0) return;
        }

        // this.#JmsServices.getDivisionOptions(this.bookingForm.options.bookingFormDivisionId).then((response) => {
        //
        //     if (response?.categories_enabled !== undefined) {
        //         this.bookingForm.options.grouping_enabled = response?.categories_enabled === true ? '1' : '0';
        //         console.log(this.bookingForm.options.grouping_enabled);
        //     }
        //
        // });

        this.getServices().then((response) => {
            this.bookingForm.setFormNotification(this.bookingForm.messages.search);

            //reset services and groups
            if (this.#divisionUpdated) {
                this.reset(serviceListContainer);

                if (this.#isServiceGroupingEnabled(this.bookingForm)) {
                    this.renderInGroups(response, serviceListContainer);
                } else {
                    this.renderSimple(response, serviceListContainer);
                }

                this.HTMLElement.dispatchEvent(this.#hooks.servicesLoaded);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.bookingForm.loader.removeServiceLoader();
        });
    }

    reset(serviceListContainer) {
        this.services = [];
        this.groups = [];
        this.selected = [];
        serviceListContainer.innerHTML = '';
    }

    renderInGroups(response, servicesContainer) {
        const options = this.bookingForm.options;
        let hiddenServices = [];
        let visibleServices = [];

        if (options.bookingFormServiceIds && options.bookingFormServiceIds.length > 0) {
            let serviceData = [];
            let groups = Object.values(response);
            groups.forEach((group) => {
                let groupServices = group.services;
                if (Array.isArray(groupServices)) {
                    //merge all groupServices into one serviceData array
                    Object.values(groupServices).forEach((service) => {
                        serviceData.push(service);
                    });
                    response = serviceData;
                }
            });
        }

        let serviceGroups = Object.values(response);

        //ordering groups by order property, the bigger the order the higher the group
        serviceGroups = serviceGroups.sort((a, b) => {
            return (a.order < b.order) ? 1 : -1;
        });

        //render groups
        serviceGroups.forEach((serviceGroup) => {
            let group = new ServiceGroup(serviceGroup.name, serviceGroup.order, serviceGroup.description, this);
            let groupServices = serviceGroup.services;

            if (!Array.isArray(groupServices)) {
                groupServices = Object.values(groupServices);
            }

            if (options.alphabetical_order === '1') {
                //sort services alphabetically
                groupServices = this.OrderServicesAlphabetically(groupServices);
            }

            groupServices = this.filterServiceVisibility(groupServices);

            if (groupServices.length > 0) {
                groupServices.forEach((service) => {
                    visibleServices.push(service.id);

                    let newService = new Service(service.friendly_name, service.id, service?.description, true);
                    newService.setParentElement(this);
                    group.addService(newService);
                    this.services.push(newService);
                });

                group.createDomElement();
                this.groups.push(group);

                servicesContainer.appendChild(group.element);
            }
        });
    }

    renderSimple(response, servicesContainer) {
        let services = this.filterServiceVisibility(Object.values(response));
        let hiddenServices = [];
        let visibleServices = [];

        services.forEach((service) => {
            //exclude services that are not in the show only services array
            if (this.bookingForm.options.show_only_services.length > 0 && this.bookingForm.options.show_only_services.indexOf(service.id) === -1) {
                return;
            }

            visibleServices.push(service.id);
            this.renderService(service);
        });

        //hidden services variable is referring to the services that are not available through the API
        //and are set in the booking form shortcode, normally they exist on FMS but are not available
        // on national level
        hiddenServices = this.bookingForm.options.bookingFormServiceIds.filter((id) => {
            return visibleServices.indexOf(id) === -1;
        });

        if (hiddenServices.length > 0) {
            hiddenServices.forEach((id) => {
                this.services.push(new Service(`Custom Service`, id));
            });
        }

        //render services
        if (this.services.length > 0) {

            if (this.bookingForm.options.alphabetical_order === '1') {
                //sort services alphabetically
                this.services = this.OrderServicesAlphabetically(this.services);
            }

            this.services.forEach((service) => {
                if (servicesContainer !== null)
                    servicesContainer.appendChild(service.element);

                if (this.bookingForm.options.bookingFormServiceIds && this.bookingForm.options.bookingFormServiceIds.indexOf(service.id) !== -1 && !service.selected) {
                    service.toggleSelected();
                }
            });

        }
    }

    renderService(service) {
        let newService = new Service(service.friendly_name, service.id);
        newService.setParentElement(this);
        this.services.push(newService);
    }

    addSelected(selected, id) {
        if (selected.indexOf(id) === -1) {
            selected.push(id);
        }

        return selected;
    }

    removeFromSelected(selected, removedId) {
        return selected.filter((id) => id !== removedId);
    }


    /**
     * Filters services based on the show only services and excluded services options
     *
     * @param response
     * @return {*}
     */
    filterServiceVisibility(response) {
        const services = response;
        const options = this.bookingForm.options;

        //check if excluded services are set or show only services are set
        // if both the show only services and excluded services are set, the excluded services will be ignored
        if (options.exclude_services.length > 0 || options.show_only_services.length > 0) {
            let excludedServices = options.exclude_services;
            let showOnlyServices = options.show_only_services;

            //if show only services are set, remove all services that are not in the show only services array
            if (showOnlyServices.length > 0) {
                response = response.filter((service) => {
                    return showOnlyServices.indexOf(service.id) !== -1;
                });

                if (response.length === 0) {
                    response = services;
                }
            }

            //if excluded services are set, remove all services that are in the excluded services array
            if (excludedServices.length > 0 && showOnlyServices.length === 0) {
                response = response.filter((service) => {
                    return excludedServices.indexOf(service.id) === -1;
                });
            }
        }

        return response;
    }

    /**
     * Orders services alphabetically
     * @param serviceList
     * @return {*}
     * @constructor
     */
    OrderServicesAlphabetically(serviceList) {
        return serviceList.sort((a, b) => {
            let aName = a.name.toLowerCase();
            let bName = b.name.toLowerCase();

            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        });
    }

    /**
     * Get the division services from the api
     * @return {Promise<unknown>}
     */
    getServices() {
        const http = new HttpRequest(this.bookingForm.options.rest_url);
        let divisionId = this.getParentProperty('options').bookingFormDivisionId;
        this.#divisionUpdated = false;

        //get the division id from the division step, if it's activated
        //loop through bookingform steps and find if any is an instance of BookingFormDivisionStep
        const divisionStep = Object.values(this.bookingForm.steps).find(step => step instanceof BookingFormDivisionStep);

        if (divisionStep !== undefined) {
            //set the division id
            divisionId = divisionStep.selected.id;
        }

        //update services only
        if (this.division === null || this.division !== divisionId) {
            this.division = divisionId;
            this.#divisionUpdated = true;

            //create query string
            let queryString = new URLSearchParams({
                'division_id': divisionId,
                'use_grouping': this.#isServiceGroupingEnabled(this.bookingForm) ? '1' : '0',
                'country_code': this.getParentProperty('options').country,
                'service_list': this.getServiceListName()
            });

            this.#memory.servicesResponse = http.get(`${this.#endpoints.services}?${queryString.toString()}`);
        }

        return this.#memory.servicesResponse;
    }

    getServiceListName() {
        return this.bookingForm.options.bookingFormServiceList !== undefined
            ? this.bookingForm.options.bookingFormServiceList
            : '';
    }

    /**
     * Returns true if the service grouping is enabled and no preselected services are set
     * @return {boolean}
     */
    isServiceGroupingEnabled() {
        const options = this.bookingForm.options;

        console.log(options.grouping_enabled);

        if (options.grouping_enabled === undefined) return false;

        return options.grouping_enabled === '1' &&
            !(options.bookingFormServiceIds && options.bookingFormServiceIds.length > 0);
    }

    /**
     * Initialize search capabilities
     */
    #enableSearch() {
        this.seachbar = this.HTMLElement.querySelector('.search-bar');
        let handler = null;
        this.seachbar.addEventListener('keyup', (evt) => {
            //set timeout and cancel previous timeout
            handler = setTimeout(() => {
                clearTimeout(handler);
                this.#search(evt.target.value);
            }, 500);
        });
    }

    #search(keyword) {
        //hide all services
        this.services.forEach((service) => {
            service.hide();

            if (service.name.toLowerCase().includes(keyword.toLowerCase())) {
                service.show();
            }
        });
    }

    /**
     * @return {boolean}
     */
    #isServiceGroupingEnabled(bookingForm) {
        return bookingForm.options.grouping_enabled === '1' &&
            !(bookingForm.options.bookingFormServiceIds && bookingForm.options.bookingFormServiceIds.length > 0);
    }
}

export default BookingFormServiceStep;