/**
 * Created by Administrator on 2023/10/26.
 */


import fmsProvider from "../types/fms-provider.mjs";

/**
 * @class PromptContractorConsent
 */
class PromptContractorConsent {


    /**
     *
     * @type {boolean}
     */
    #accept = false

    /**
     *
     * @type {HTMLElement}
     */
    #container = null

    /**
     *
     * @type {HTMLElement}
     */
    #nameElement = null


    /**
     *
     * @type {HTMLElement}
     */
    #companyNameElement = null

    /**
     * @type {BookingForm}
     */
    #parent = null


    /**
     *
     * @type {boolean}
     */
    #status = false


    #callback = null

    /**
     *
     * @type {{subheader: string, header: string, message: string}}
     */
    #labels = {
        'header': 'No Franchisee Available',
        'subheader': 'We have an independent contractor "{{contractor}}" who will call you{{period}} to organise an obligation free quote.',
        'message': 'I consent, please pass my contact details to an independent contractor for a quote.',
        'work_guarantee': 'This contractor is backed by our Jim’s $1,000 work guarantee.', //Covered by a $1,000 Jim's Group Workmanship Guarantee
    };

    /**
     * @param parent {BookingForm}
     */
    constructor(parent, labels = {}) {
        this.#parent = parent;

        if (Object.keys(labels).length > 0) {
            this.#labels = labels;
        }
    }

    init() {
        this.#container = this.#parent.formElement.querySelector(`.${this.#parent.prefixClass}-loader-wrapper`);
        this.#nameElement = this.#container.querySelector('.data-consent .fse-name');
        this.#companyNameElement = this.#container.querySelector('.data-consent .company-name');

        this.listen();
    }

    open() {
        this.#status = true;
        if (this.#container === undefined) {
            this.init();
        }

        if (this.#container) {
            this.#container.classList.add('consent', 'open');
        } else {
            throw new Error('Contractor Prompt Container not found');
        }
    }

    close(soft = true) {
        this.#status = false;

        if (soft) {
            this.#container.classList.remove('consent');
        } else {
            this.#container.classList.remove('consent', 'open');
        }
    }

    /**
     *
     * @return {boolean}
     */
    getStatus() {
        return this.#status;
    }

    /**
     *
     * @return {boolean}
     */
    getAnswer() {
        return this.#accept;
    }

    /**
     *
     * @param {Number} number
     */
    setStars(number) {
        number = Math.round(number.toFixed(2) );

        let stars = this.#container.querySelectorAll('.rating-stars .star');
        stars.forEach((star, index) => {
            if (index < number) {
                star.classList.add('active');
            } else {
                star.classList.remove('active');
            }
        })
    }

    setName(name) {
        this.#nameElement.innerText = name;
    }

    showWorkGuarantee() {
        this.#container.querySelector('span.contractor-work-guarantee').classList.remove('hidden');
    }

    hideWorkGuarantee() {
        this.#container.querySelector('span.contractor-work-guarantee').classList.add('hidden');
    }

    showCompanyName(companyName = '') {
        if ( companyName !== null && companyName.length > 0) {
            this.#companyNameElement.classList.remove('hidden');
            this.#companyNameElement.innerText = companyName;
        } else {
            this.#companyNameElement.classList.add('hidden');
        }
    }

    setRating(number, minRating = 3) {
        //round number to 2 decimals
        let rating = Number(number);

        this.setStars(rating);
        this.#container.querySelector('.rating.stars .rating-count').classList.remove('hidden');

        if(parseFloat( rating ) > minRating){
            this.#container.querySelector('.rating.stars').classList.remove('hidden');
            this.#container.querySelector('.rating.stars .rating-count').classList.remove('hidden');
            this.#container.querySelector('.contractor-no-rating').classList.add('hidden');
            this.#container.querySelector('.rating.stars .rating-count').innerText = number.toFixed(1);
        }else {
            this.#container.querySelector('.rating.stars').classList.add('hidden');
            this.#container.querySelector('.rating.stars .rating-count').classList.add('hidden');
            this.#container.querySelector('.contractor-no-rating').classList.remove('hidden');
        }
    }

    setPhoto(fseData = null){
        if (fseData) {
            const contractor = new fmsProvider(fseData, {
                'siteUrl': this.#parent.options.rest_url
            });

            contractor.setPhoto('.data-consent .fse-photo img');
        }
    }

    hasLabels() {
        //check if labels are set and if they are not empty
        return Object.keys(this.#labels).length > 0
            && Object.values(this.#labels).every((label) => label.length > 0);
    }

    setLabels(vars) {
        //replace vars in labels
        Object.keys(this.#labels).forEach((key) => {
            this.#labels[key] = this.#labels[key].replace(/{{(.*?)}}/g, (match, p1) => {
                return vars[p1];
            });
        });


        this.#container.querySelector('.data-consent h3').innerText = this.#labels.header;
        this.#container.querySelector(`.data-consent .${this.#parent.prefixClass}-data-consent-message .consent-subheader`).innerText = `${this.#labels.subheader}`;
        this.#container.querySelector(`.data-consent .${this.#parent.prefixClass}-data-consent-message .contractor-work-guarantee`).innerText = this.#labels.work_guarantee;
        this.#container.querySelector(`.data-consent .${this.#parent.prefixClass}-data-consent-consent`).innerText = this.#labels.message;
    }

    listen() {
        this.#container.querySelector(`.data-consent`).addEventListener('click', (e) => {
            e.preventDefault();

            if(e.target.classList.contains('accept') || e.target.classList.contains('decline')) {
                this.#accept = !!e.target.classList.contains('accept');


                //close the prompt
                this.close(true);

                //if callback is set, call it
                this.#parent.formElement.dispatchEvent(this.#parent.onContractorConsent);
            }

            return false;
        })
    }
}

export default PromptContractorConsent;
