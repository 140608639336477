/**
 * Error Message
 */
const ErrorMessage = {
    default: 'Booking Processing Error. We apologize for the inconvenience. Unfortunately, we cannot process your request online at this time. However your inquiry has been forwarded to our Call Centre, and they will call you shortly.',
    previous_response: 'The data appeared to be incomplete. Please try again.',
    address: 'Invalid address, the address can\'t be validated',
    fms_errors: [
        'Booked',
        'We currently don’t have a franchisee available, but this can change minute to minute.  We will contact you in the next few hours if someone becomes available. Thanks',
        'Service Already Requested. We\'re sorry, it appears that a service request for this address was submitted within the last 12 hours. If you haven\'t made the booking, please call our Call Centre for any questions or immediate assistance.',
        'We currently don’t have a franchisee available, but this can change minute to minute.  We will contact you in the next few hours if someone becomes available. Thanks',
        'Invalid Address. We couldn\'t locate your address, but don\'t worry. Your inquiry has been forwarded to our Call Centre, and they will contact you shortly.',
        'Booking Processing Error. We apologize for the inconvenience. Unfortunately, we cannot process your request online at this time. However your inquiry has been forwarded to our Call Centre, and they will call you shortly.',
        'Booking Processing Error. We apologize for the inconvenience. Unfortunately, we cannot process your request online at this time. However your inquiry has been forwarded to our Call Centre, and they will call you shortly.',
        'This is a test mode message. No action is required.',
        'Service Already Requested. We\'re sorry, it appears that a service request for this address was submitted within the last 12 hours. If you haven\'t made the booking, please call our Call Centre for any questions or immediate assistance.',
        'Booking Processing Error. We apologize for the inconvenience. Unfortunately, we cannot process your request online at this time. However your inquiry has been forwarded to our Call Centre, and they will call you shortly.',
        'Booking Processing Error. We apologize for the inconvenience. Unfortunately, we cannot process your request online at this time. However your inquiry has been forwarded to our Call Centre, and they will call you shortly.'
    ]
};

export default ErrorMessage;