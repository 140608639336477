import ConnectionStatus from "../services/connection-status.mjs";

class OnlineStatus {

    /**
     * @type {null|ConnectionStatus}
     */
    connectedStatus = null;

    /**
     *
     * @type {null|bookingForm}
     */
    bookingForm = null;

    constructor(parent, selector) {
        this.bookingForm = parent;
        this.connectedStatus = this.bookingForm.connectionStatus;
        this.htmlElement = this.getHTMLElement(selector);

        this.initialize();
    }

    initialize() {
        this.setOnlineStatus();
        this.connectedStatus.addListener(this.updateStatus.bind(this));
    }


    setOnlineStatus() {
        this.updateStatus(this.connectedStatus.online, this.connectedStatus.network);
    }

    updateStatus(online, network) {
        if (online) {
            this.htmlElement.setAttribute('title', 'The Form is Online');

            if (network) {
                this.htmlElement.setAttribute('title', `The Form is Online, ${network.effectiveType} connection detected.`);
            }

        } else {
            this.htmlElement.setAttribute('title', 'The Form is Offline, please reconnect to the internet to continue.');
        }
    }

    getHTMLElement(selector) {
        let elem = document.querySelector(selector);

        if (!elem) {
            throw new Error('connection status component is not defined');
        }

        return elem;
    }

}

export default OnlineStatus;