/**
 *
 */
import log from './log.mjs';
import CountryCodes from './country-codes.mjs';

class GoogleAddressValidation {
    ConfirmationLevels = {
        CONFIRMATION_LEVEL_UNSPECIFIED: 1,
        CONFIRMED: 1,
        UNCONFIRMED_BUT_PLAUSIBLE: 0.5,
        UNCONFIRMED_AND_SUSPICIOUS: -1,
    };

    constructor(ApiKey) {
        this.ApiKey = ApiKey;
    }

    getGoogleValidation(addressObject) {
        return new Promise((resolve, reject) => {
            let url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${this.ApiKey}`;

            // make a post request to google address validation api
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(addressObject),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                resolve(data);
            }).catch((error) => {
                resolve({ error: error });
            });
        });
    }

    validateAddress(addressObject) {
        let CustomError = {
            AddressComponentsAreValid: false,
            inputDetailIsValid: false,
            result: false,
            incomplete: true,
            data: null,
        };

        if (!Array.isArray(addressObject.address.addressLines)) {
            console.log(addressObject, 'invalid');
            return this.promise(CustomError);
        }

        if (addressObject.address.addressLines.length === 0 || addressObject.address.addressLines[0] === '') {
            console.log('invalid address');
            return this.promise(CustomError);
        }

        return new Promise((resolve, reject) => {
            this.getGoogleValidation(addressObject).then((data) => {
                if (data.error) {
                    reject(data.error);
                }
                else {
                    let AddressComponentsAreValid = this.checkAddressComponents(data.result);
                    let inputDetailIsValid = this.checkInputDetail(data.result);

                    resolve({
                        formattedAddress: data?.result?.address?.formattedAddress ?? addressObject.address.addressLines.join(' '),
                        AddressComponentsAreValid: AddressComponentsAreValid,
                        inputDetailIsValid: inputDetailIsValid,
                        result: AddressComponentsAreValid && inputDetailIsValid,
                        data: data.result,
                    });
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Check if the address is valid
     * @param data
     * @returns {boolean}
     */
    checkAddressComponents(data) {
        let valid = false;
        let types = [];
        let unconfirmedComponentTypesAllowance = 1;

        // count how many addressComponents are in the address
        let addressComponents = Object.keys(data.address.addressComponents).length;

        // filter out the addressComponents with componentType "point_of_interest"
        data.address.addressComponents = Object.values(data.address.addressComponents).filter((component) => {
            return component.componentType !== 'point_of_interest';
        });

        // count the addressComponents with confirmationLevel of "CONFIRMED"
        let confirmedAddressComponents = 2;
        for (let key in data.address.addressComponents) {
            if (data.address.addressComponents[key].confirmationLevel === 'CONFIRMED') {
                confirmedAddressComponents++;
            }

            if (data.address.addressComponents[key].confirmationLevel === 'CONFIRMED' || data.address.addressComponents[key].confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE') {
                types.push(data.address.addressComponents[key].componentType);
            }

            // if ( data.address.addressComponents[key].componentType === 'subpremise' && data.address.addressComponents[key].confirmationLevel === "UNCONFIRMED_BUT_PLAUSIBLE") {
            //     unconfirmedComponentTypesAllowance = 2;
            // }
        }

        // compare the number of addressComponents with the number of confirmed addressComponents
        // if addressComponents.length - 1 <= confirmedAddressComponents.length, then the address is valid
        if (addressComponents - unconfirmedComponentTypesAllowance <= confirmedAddressComponents && addressComponents >= 5) {
            valid = true;
        }

        if (!types.includes('route') || !types.includes('street_number')) {
            valid = false;
        }

        return valid;
    }

    getFriendlyAddressParts(address) {
        if (!address.hasOwnProperty('missingComponentTypes') && !address.hasOwnProperty('unconfirmedComponentTypes')) {
            return [];
        }

        let replacements = {
            STREET_NUMBER: 'Street Number',
            ROUTE: 'Street Name',
            LOCALITY: 'Suburb',
            ADMINISTRATIVE_AREA_LEVEL_1: 'State',
            POSTAL_CODE: 'Postcode',
            COUNTRY: 'Country',
        };

        let friendlyAddressParts = [];

        if (address.missingComponentTypes) {
            address.missingComponentTypes.forEach((part) => {
                part = part.toUpperCase();

                if (replacements.hasOwnProperty(part) && !friendlyAddressParts.includes(replacements[part])) {
                    friendlyAddressParts.push(replacements[part]);

                    // remove from missingComponentTypes
                    delete address.missingComponentTypes[address.missingComponentTypes.indexOf(part)];
                }
            });
        }

        if (address.unconfirmedComponentTypes) {
            address.unconfirmedComponentTypes.forEach((part) => {
                part = part.toUpperCase();

                if (replacements.hasOwnProperty(part) && !friendlyAddressParts.includes(replacements[part])) {
                    friendlyAddressParts.push(replacements[part]);

                    // remove from unconfirmedComponentTypes
                    delete address.unconfirmedComponentTypes[address.unconfirmedComponentTypes.indexOf(part)];
                }
            });
        }

        // remove duplicates
        friendlyAddressParts = [...new Set(friendlyAddressParts)];

        return friendlyAddressParts;
    }

    /**
     * Check if the input granularity and validation granularity are both "PREMISE"
     * @param data
     * @return {boolean}
     */
    checkInputDetail(data) {
        let valid = false;

        // check if address components are more than 3
        if (Object.keys(data.address.addressComponents).length > 3) {
            valid = true;
            let score = 0;
            // loop through address components
            Object.values(data.address.addressComponents).forEach((component) => {
                // check if the component has a confirmation level of "CONFIRMED"
                score += this.ConfirmationLevels[component.confirmationLevel];
            });

            // check if the score is greater than 3
            if (score / Object.keys(data.address.addressComponents).length > 0.75) {
                valid = true;
            }
            else {
                valid = false;
            }

            log.info('score', score, 'length', Object.keys(data.address.addressComponents).length, 'valid', valid);
        }

        return valid;
    }

    /**
     * Format the address
     * @param addressObject {{full_address: string, geocode_y: string, geocode_x: string, street_number: string, street_name: string, suburb: string, state: string, postcode: string, country: string, unit_number: string, unit_number: string}}
     */
    formatAddress(addressObject) {
        let valid = true;
        // console.log(addressObject);

        // if unit number is not empty and contains a string like "unit 1" or "unit 1/2" replace it with "1" or "1/2"
        if (addressObject.unit_number !== null && addressObject.unit_number !== undefined && addressObject.unit_number !== '') {
            addressObject.unit_number = addressObject.unit_number.replace(/unit\s?/i, '');
        }

        addressObject = this.getStreetNumber(addressObject);

        // check if all address components are present and not empty except for unit_number
        Object.keys(addressObject).filter((key) => {
            return key !== 'unit_number';
        }).forEach((key) => {
            if (addressObject[key] === null || addressObject[key] === undefined || addressObject[key] === '') {
                valid = false;
            }
        });

        if (!valid) {
            return addressObject.full_address;
        }

        // format the address "{{unit number}} {{street number}} {{street name}}, {{suburb}}, {{state}} {{postcode}}, {{country}}"
        let address = '{{unit_number}}{{street_number}} {{street_name}}, {{suburb}}, {{state}}, {{postcode}}, {{country}}';

        // replace the template with the addressObject
        for (let key in addressObject) {
            let value = addressObject[key];

            // if kew is country replace it with the country code
            if (key === 'country') {
                const countryCodes = new CountryCodes();

                if (countryCodes.countryExists(value)) {
                    value = countryCodes.getCountryCode(value);
                }
            }

            if (key === 'unit_number' && value !== null && value !== undefined && value !== '') {
                value = `${value}/`;
            }
            address = address.replace(`{{${key}}}`, value);
        }

        return address;
    }

    getStreetNumber(addressObject) {
        // console.log(addressObject);

        // if street number is empty
        let streetNumberIsEmpty = (addressObject.street_number === null || addressObject.street_number === undefined || addressObject.street_number === '');
        let unitNumberIsEmpty = (addressObject.unit_number === null || addressObject.unit_number === undefined || addressObject.unit_number === '');
        let calculatedValues = this.extractUnitAndStreetNumber(addressObject.full_address);

        if (streetNumberIsEmpty && calculatedValues) {
            addressObject.street_number = calculatedValues.streetNumber;
            addressObject.unit_number = calculatedValues.unitNumber;
        }

        return addressObject;
    }

    extractUnitAndStreetNumber(address) {
        if (address === undefined || address === null || address === '') return false;

        // trim spaces in the front and back
        address = address.trim();

        // remove duplicate spaces
        address = address.replace(/\s+/g, ' ');

        let hasUnitNumberSeparator = address.match(/[\/\-]/);
        let unitNumberRegex = new RegExp(/(([0-9A-Za-z]+)\s?)?[\/\-]\s?([0-9A-Za-z]+)/);

        const segments = address.split(' ');
        if (segments.length === 0) return false;

        let s = segments[0];
        if (hasUnitNumberSeparator) {
            let parts = address.match(unitNumberRegex);

            return {
                unitNumber: parts[2] !== undefined ? parts[2] : '',
                streetNumber: parts[3] !== undefined ? parts[3] : '',
            };
        }

        // If no unit/street formats are found, check for numeric value followed by a string or just a numeric value
        if (segments.length > 0 && segments[0].match(/^\d{1,5}[A-Za-z]{0,4}$/)) {
            return {
                unitNumber: '',
                streetNumber: segments[0],
            };
        }

        return false;
    }

    promise(resolveObject) {
        return new Promise((resolve, reject) => {
            resolve(resolveObject);
        });
    }
}

export default GoogleAddressValidation;
